import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import DeviceList from "../../../domain/Device/DeviceList";
import { RoleContext } from "../../../services/Api/Role";
import LoadingIcon from "../../../components/LoadingIcon";

const ListDevices = () => {
  const { role } = useContext(RoleContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (role != null) {
      setIsLoaded(true);
    }
  }, [role]);

  let content;
  let adminLink;
  if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    if (role === "superadmin") {
      adminLink = (
        <span data-testid="register-device">
          <h1 className="subtitle has-text-right">
            <a href={"/devices/register"}>Register New Machine</a>
          </h1>
        </span>
      );
    }
    content = (
      <div>
        <div className="ViewHeader">
          <span className="ViewHeader-title">
            <h1 className="title">Fleet View</h1>
          </span>
          {adminLink}
        </div>
        <div className="columns is-centered">
          <div className="box column is-12">
            <DeviceList />
          </div>
        </div>
      </div>
    );
  }

  return <>{content}</>;
};

export default ListDevices;
