import "./style.css";

import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";

import Filter from "../../../components/Filter";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import SortTableHeader from "../../../components/SortTableHeader";

const KickoutReport = () => {
  const [kickouts, setKickouts] = useState([]);
  const [deviceKickouts, setDeviceKickouts] = useState([]);
  const [errorMessageMap, setErrorMessageMap] = useState({});
  const [error, setError] = useState(null);
  const [device, setDevice] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDeviceKickoutLoaded, setIsDeviceKickoutLoaded] = useState(false);
  const { get } = useContext(AuthClientContext);
  const [sort, setSort] = useState("id");
  const [desc, setDesc] = useState(false);

  const [params, setParams] = useState(null);

  const clickSort = (val) => {
    if (val === sort) {
      setDesc(!desc);
    } else {
      setSort(val);
      setDesc(true);
    }
  };

  useEffect(() => {
    if (params) {
      get(`/v1/metrics/scrubber/report/kickouts`, {
        params: {
          ...params,
          size: 100,
          sortBy: `${sort}:${desc ? "desc" : "asc"}`,
        },
      }).then(
        (result) => {
          setKickouts(result.data);
          setErrorMessageMap(
            result.data.reduce((agg, item) => {
              agg[`${item.id}`] = item.name;
              return agg;
            }, {})
          );
          setIsLoaded(true);
        },
        (error) => {
          setError(error);
          setIsLoaded(true);
        }
      );
    }
  }, [get, params, sort, desc]);

  useEffect(() => {
    if (params) {
      get(`/v1/metrics/scrubber/report/device-kickouts`, {
        params: {
          ...params,
          size: 100,
          sortBy: `${sort}:${desc ? "desc" : "asc"}`,
        },
      }).then(
        (result) => {
          setDeviceKickouts(result.data);
          setIsDeviceKickoutLoaded(true);
        },
        (error) => {
          setError(error);
          setIsDeviceKickoutLoaded(true);
        }
      );
    }
  }, [get, params, sort, desc]);

  let kickoutChart;
  let deviceKickoutChart;
  if (error) {
    kickoutChart = <ErrorIcon text={error.message} />;
  } else if (!params || !isLoaded || !isDeviceKickoutLoaded) {
    kickoutChart = <LoadingIcon />;
  } else {
    kickoutChart = (
      <table className="table is-hoverable">
        <thead>
          <tr>
            <SortTableHeader
              name="ID"
              field="id"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("id")}
            />

            <SortTableHeader
              name="Count"
              field="count"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("count")}
            />

            <SortTableHeader
              name="Name"
              field="name"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("name")}
            />
          </tr>
        </thead>
        <tbody>
          {kickouts.map((item, i) => (
            <tr key={i}>
              <td>{item.id}</td>
              <td>{item.count}</td>
              <td>{item.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
    deviceKickoutChart = (
      <table className="table is-hoverable">
        <thead>
          <tr>
            <th>Autonomy ID</th>
            <th>Alias</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {deviceKickouts.map((item, i) => (
            <tr
              key={i}
              onClick={() => setDevice(item)}
              className={`${
                device && device.id === item.id ? "is-selected" : undefined
              }`}
              style={{ cursor: "pointer" }}
            >
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.total_kickouts}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  return (
    <div data-testid="admin-dashboard">
      <h1 key="dashboard-title" className="title">
        Accumulated Kickout Reports
      </h1>
      <Filter handler={setParams} />
      <div className="ReportPlaybackRunsHeader">
        <span className="RunsTitle"></span>
      </div>
      <div className="box ReportPlaybackTable">{kickoutChart}</div>
      <div className="box ReportPlaybackTable">{deviceKickoutChart}</div>
      {device !== null ? (
        <div className="box ReportPlaybackTable">
          <table className="table is-hoverable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Message</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(device.kickouts).map((item, i) => {
                const [key, value] = item;
                return (
                  <tr key={i}>
                    <td>{key}</td>
                    <td>{errorMessageMap[`${key}`]}</td>
                    <td>{value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : undefined}
    </div>
  );
};

export default KickoutReport;
