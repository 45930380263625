import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import MultiselectDropdown from "../../../components/MultiselectDropdown";

const OrganizationMultiselectDropdown = ({
  handleSelect = () => {},
  selected = [],
}) => {
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    get(`/v1/tenants/`).then(
      (result) => {
        setList(
          result.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          })
        );
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [get]);

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <MultiselectDropdown
        title="Organizations"
        items={list}
        selected={selected}
        handleSelect={handleSelect}
      />
    );
  }

  return content;
};

export default OrganizationMultiselectDropdown;
