import React from "react";
import OrganizationList from "../../../../domain/Organization/OrganizationList";

const ListOrganizations = () => {
  return (
    <div>
      <a
        href="/organizations/create"
        className="button is-link is-pulled-right"
      >
        <span className="icon">
          <i className="fas fa-plus"></i>
        </span>
        <span>Add</span>
      </a>
      <h1 className="title">Organizations</h1>
      <div className="columns is-centered">
        <div className="box column is-12">
          <OrganizationList />
        </div>
      </div>
    </div>
  );
};

export default ListOrganizations;
