import "./style.css";
import Moment from "react-moment";
import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import { Link } from "react-router-dom";

const PlaybackList = ({ id, startDate, endDate }) => {
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    let params = {};
    if (startDate) {
      params["start_date"] = startDate.format();
    }
    if (endDate) {
      params["end_date"] = endDate.format();
    }
    get(`/v1/metrics/scrubber/devices/${id}/playbacks`, {
      params: params,
    }).then(
      (result) => {
        setList(
          result.data.sort((a, b) => (a.start_time > b.start_time ? -1 : 1))
        );
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get, startDate, endDate]);

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <table data-testid="playback-table-body" className="table is-hoverable">
        <thead>
          <tr>
            <th>
              Start Time <br /> (UTC{moment().format("Z")})
            </th>
            <th>
              End Time <br /> (UTC{moment().format("Z")})
            </th>
            <th>Plan</th>
            <th>Runtime</th>
            <th>Area Cleaned (M&sup2;)</th>
            <th>Kickouts</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, i) => (
            <tr key={i}>
              <td className="link-text date-column">
                <Link to={`/playbacks/${item.id}`}>
                  <Moment format="YYYY-MM-DD HH:mm" utc local>
                    {item.start_time}
                  </Moment>
                </Link>
              </td>
              <td className="link-text date-column">
                <Link to={`/playbacks/${item.id}`}>
                  <Moment format="YYYY-MM-DD HH:mm" utc local>
                    {item.end_time}
                  </Moment>
                </Link>
              </td>
              <td>{item.plan_name}</td>
              <td>
                {moment
                  .duration(item.elapsed_time, "seconds")
                  .format("hh:mm", { trim: false })}
              </td>
              <td>
                {item.total_cleaned_area_m2
                  ? item.total_cleaned_area_m2.toFixed(1)
                  : "-"}
              </td>
              <td>
                {item.total_kickouts - item.autoresumed_count > 0
                  ? item.total_kickouts - item.autoresumed_count
                  : 0}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <div data-testid="playback-table" className="PlaybackList">
      <div className="table-heading">Playbacks</div>
      <div className="PlaybackList-body ">{content}</div>
    </div>
  );
};

export default PlaybackList;
