import React from "react";
import { Redirect } from "react-router-dom";
// import SignInForm from "../SignInForm";
import OktaWidget from "../OktaWidget";
import { useOktaAuth } from "@okta/okta-react";

const SignIn = () => {
  const { authState } = useOktaAuth();

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <OktaWidget />
  );
};

export default SignIn;
