import "./style.css";
import React from "react";
import PlanList from "../../../domain/Plan/PlanList";
import { useHistory } from "react-router-dom";

const ListPlans = () => {
  const history = useHistory();

  const rowClick = (id) => {
    history.push(`/plans/${id}`);
  };

  return (
    <div>
      <h1 className="title">Plans</h1>
      <div className="columns is-centered">
        <div className="box column is-12">
          <PlanList rowClick={rowClick} />
        </div>
      </div>
    </div>
  );
};

export default ListPlans;
