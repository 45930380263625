import "./style.css";
import React from "react";

const LandingLink = ({ name, icon, link, disabled = false }) => {
  const mainContent = (
    <div className={"card LandingLink" + (disabled ? " is-disabled" : "")}>
      <div className="card-image ">
        <i className={"fas fa-w-20 fa-5x " + icon}></i>
      </div>
      <div className="card-content">
        <div className="content">
          <h2>{name}</h2>
        </div>
      </div>
    </div>
  );

  const content = disabled ? mainContent : <a href={link}>{mainContent}</a>;
  return <div className="column is-one-quarter">{content}</div>;
};
export default LandingLink;
