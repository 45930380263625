import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import { useHistory } from "react-router-dom";
import ErrorIcon from "../../../components/ErrorIcon";
import LoadingIcon from "../../../components/LoadingIcon";

const DeviceRegistration = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [autonomyKit, setAutonomyKit] = useState("");
  const [tenant, setTenant] = useState("");
  const [includeHistory, setIncludeHistory] = useState(false);
  const [tenantDropdown, setTenantDropdown] = useState([]);
  const [tenantDropdownError, setTenantDropdownError] = useState(null);
  const [tenantError, setTenantError] = useState(null);
  const [autonomyError, setAutonomyError] = useState("");
  const [submitError, setSubmitError] = useState(null);
  const { get, post } = useContext(AuthClientContext);
  const [buttonDisabled, disableButton] = useState(false);
  const history = useHistory();

  const buttonRedirect = () => {
    history.push(`/devices`);
  };

  useEffect(() => {
    get(`/admin/v1/tenants`).then(
      (result) => {
        setTenantDropdown(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setTenantDropdownError(error);
        setIsLoaded(true);
      }
    );
  }, [get]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAutonomyError("");
    setTenantError("");
    disableButton(true);
    let isalphanumeric = /^[a-z0-9]+$/i.test(autonomyKit);
    if (!isalphanumeric) {
      setAutonomyError(
        "Autonomy Kit must be alphanumeric and at least 1 character long"
      );
      return;
    }
    if (!tenant) {
      setTenantError("Please select a Tenant");
      return;
    }
    post(`/v1/devices/register`, {
      autonomy_kit: autonomyKit,
      tenant: tenant,
      include_historical_data: includeHistory,
    }).then(
      (result) => {
        buttonRedirect();
      },
      (error) => {
        disableButton(false);
        setSubmitError(error);
      }
    );
  };

  let tenantContent = "";
  if (tenantDropdownError) {
    tenantContent = <ErrorIcon text={tenantDropdownError.message} />;
  } else if (!isLoaded) {
    tenantContent = <LoadingIcon />;
  } else {
    tenantContent = (
      <>
        <div className="select">
          <select onChange={(e) => setTenant(e.target.value)}>
            <option value="" defaultValue>
              Select
            </option>
            {tenantDropdown.map((x, i) => (
              <option key={i} value={x.id}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }
  let submitContent = "";
  if (submitError) {
    submitContent = <ErrorIcon text={submitError.message} />;
  }
  return (
    <div>
      <h1 className="title">Register New Machine</h1>
      <div className="columns">
        <div className="column is-half">
          <form
            className="box"
            onSubmit={(e) => handleSubmit(e)}
            data-testid="device-registration-form"
          >
            <div className="field">
              <label id="autonomy-kit-label" className="label">
                Autonomy Kit
              </label>
              <div className="control">
                <input
                  aria-labelledby="autonomy-kit-label"
                  className="input"
                  type="text"
                  onChange={(e) => setAutonomyKit(e.target.value)}
                />
              </div>
              <p
                data-testid="error-autonomy"
                id="error-autonomy"
                className="help is-danger"
              >
                {autonomyError}
              </p>
            </div>
            <div className="field">
              <label className="label">Organization</label>
              <div className="control">{tenantContent}</div>
              <p
                data-testid="error-tenant"
                id="error-tenant"
                className="help is-danger"
              >
                {tenantError}
              </p>
            </div>
            <div className="field pt-3">
              <label className="checkbox">
                <input
                  className="mr-1"
                  type="checkbox"
                  onChange={(e) => setIncludeHistory(e.target.checked)}
                />
                Include Historic Data
              </label>
            </div>
            <hr />
            <div className="field is-grouped">
              <div className="control">
                <button
                  data-testid="button-submit"
                  className="button is-link"
                  disabled={buttonDisabled}
                >
                  Submit
                </button>
              </div>
              <div className="control">
                <button
                  data-testid="button-cancel"
                  className="button is-link is-light"
                  onClick={() => buttonRedirect()}
                >
                  Cancel
                </button>
              </div>
            </div>
            {submitContent}
          </form>
        </div>
      </div>
    </div>
  );
};
export default DeviceRegistration;
