import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";

const TroubleshootList = ({ rowClick }) => {
  const [troubleshoots, setTroubleshoots] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    get(`/v1/troubleshoot_messages/`).then(
      (result) => {
        setTroubleshoots(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [get]);

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <table
        data-testid="troubleshoot-table"
        className="table TroubleshootList is-hoverable"
      >
        <thead>
          <tr>
            <th>Display Message</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {troubleshoots.map((item) => (
            <tr key={item.id} onClick={() => rowClick(item.id)}>
              <td className="link-text">{item.display_message}</td>
              <td>{item.summary}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return <div className="column is-12">{content}</div>;
};

export default TroubleshootList;
