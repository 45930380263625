import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { RoleContext } from "../../../services/Api/Role";
import LoadingIcon from "../../../components/LoadingIcon";
import DeviceTransfer from "../../../domain/Device/DeviceTransfer";

const TransferDevice = () => {
  const { role } = useContext(RoleContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (role != null) {
      setIsLoaded(true);
    }
  }, [role]);

  let content;
  if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content =
      role === "superadmin" ? (
        <DeviceTransfer />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      );
  }

  return <>{content}</>;
};
export default TransferDevice;
