import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import ErrorIcon from "../../../components/ErrorIcon";
import LoadingIcon from "../../../components/LoadingIcon";
import { useHistory } from "react-router-dom";

const UserCreation = ({ role, org }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [organization, setOrganization] = useState("");
  const [organizationDropdown, setOrganizationDropdown] = useState([]);
  const [organizationDropdownError, setOrganizationDropdownError] = useState(
    null
  );
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [rolesError, setRolesError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [submitError, setSubmitError] = useState(null);
  const { get, post } = useContext(AuthClientContext);
  const history = useHistory();

  useEffect(() => {
    if (role === "admin") {
      setRoles(["user"]);
    }
  }, [role]);

  useEffect(() => {
    if (role === "superadmin") {
      get(`/admin/v1/organizations`).then(
        (result) => {
          let sorted = result.data.sort((a, b) =>
            a.name.toUpperCase().localeCompare(b.name.toUpperCase())
          );
          setOrganizationDropdown(sorted);
          setIsLoaded(true);
        },
        (error) => {
          setOrganizationDropdownError(error);
          setIsLoaded(true);
        }
      );
    } else if (org) {
      setOrganization(org);
      setIsLoaded(true);
    }
  }, [get, role, org]);

  const handleRoleInput = (e) => {
    let selected = e.target.selectedOptions;
    let values = [];
    for (let i = 0; i < selected.length; i++) {
      values.push(selected[i].value);
    }
    setRoles(values);
  };

  const buttonRedirect = () => {
    history.push(`/users`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setRolesError("");
    setOrganizationError("");
    if (!firstName) {
      setFirstNameError("Please input a first name");
    } else if (!lastName) {
      setLastNameError("Please input a last name");
    } else if (!email) {
      setEmailError("Please input an email address");
    } else if (roles.length === 0) {
      setRolesError("Please choose one or more roles");
    } else if (role === "superadmin" && !organization) {
      setOrganizationError("Please choose an organization");
    }
    if (
      !firstName ||
      !lastName ||
      !email ||
      roles.length === 0 ||
      organizationError !== ""
    ) {
      return;
    }
    post(`admin/v1/users`, {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      roles: roles,
      organization: organization,
    }).then(
      (result) => {
        buttonRedirect();
      },
      (error) => {
        setSubmitError(error);
      }
    );
  };

  let organizationContent = "";
  if (organizationDropdownError) {
    organizationContent = (
      <ErrorIcon text={organizationDropdownError.message} />
    );
  } else if (!isLoaded) {
    organizationContent = <LoadingIcon />;
  } else {
    organizationContent = (
      <>
        <div className="select">
          <select onChange={(e) => setOrganization(e.target.value)}>
            <option value="" defaultValue>
              Select
            </option>
            {organizationDropdown.map((x, i) => (
              <option key={i} value={x.id}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }
  let submitErrorContent = "";
  if (submitError) {
    submitErrorContent = <ErrorIcon text={submitError.message} />;
  }
  return (
    <div>
      <h1 className="title">Create New User</h1>
      <div className="columns">
        <div className="column is-half">
          <form
            className="box"
            onSubmit={(e) => handleSubmit(e)}
            data-testid="user-creation-form"
          >
            <div className="field">
              <label id="first-name-label" className="label">
                First Name
              </label>
              <div className="control">
                <input
                  aria-labelledby="first-name-label"
                  className="input"
                  type="text"
                  placeholder="Text input"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <p
                data-testid="error-first-name"
                id="error-first-name"
                className="help is-danger"
              >
                {firstNameError}
              </p>
            </div>
            <div className="field">
              <label id="last-name-label" className="label">
                Last Name
              </label>
              <div className="control">
                <input
                  aria-labelledby="last-name-label"
                  className="input"
                  type="text"
                  placeholder="Text input"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <p
                data-testid="error-last-name"
                id="error-last-name"
                className="help is-danger"
              >
                {lastNameError}
              </p>
            </div>
            <div className="field">
              <label id="email-label" className="label">
                Email
              </label>
              <div className="control has-icons-left">
                <input
                  aria-labelledby="email-label"
                  className="input"
                  type="text"
                  placeholder="Text input"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </div>
              <p
                data-testid="error-email"
                id="error-email"
                className="help is-danger"
              >
                {emailError}
              </p>
            </div>
            {role === "superadmin" && (
              <div className="field">
                <label id="roles-label" className="label">
                  Roles
                </label>
                <div className="control" aria-labelledby="roles-label">
                  <div className="select">
                    <select onChange={(e) => handleRoleInput(e)}>
                      <option value="user">User</option>
                      <option value="admin">Admin</option>

                      <option value="superadmin">Super Admin</option>
                    </select>
                  </div>
                </div>
                <p
                  data-testid="error-roles"
                  id="error-roles"
                  className="help is-danger"
                >
                  {rolesError}
                </p>
              </div>
            )}
            {role === "superadmin" && (
              <div className="field">
                <label id="organization-label" className="label is-pulled-left">
                  Organization
                </label>
                <div className="control" aria-labelledby="organization-label">
                  {organizationContent}
                </div>
                <p
                  data-testid="error-organization"
                  id="error-organization"
                  className="help is-danger"
                >
                  {organizationError}
                </p>
              </div>
            )}
            <div className="field is-grouped">
              <div className="control">
                <button data-testid="button-submit" className="button is-link">
                  Submit
                </button>
              </div>
            </div>
            {submitErrorContent}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserCreation;
