import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import PanelMap from "./PanelMap";
import ModalMap from "./ModalMap";

const PlaybackMap = ({ id, planID, wrapper = true, hideButton = false }) => {
  const [maps, setMaps] = useState([]);
  const [planMap, setPlanMap] = useState(null);
  const [segments, setSegments] = useState([]);
  const [kickouts, setKickouts] = useState([]);
  const [matrix, setMatrix] = useState("matrix(0 0 0 0 0 0)");

  const [showPath, togglePath] = useState(false);
  const [kickoutInfo, setKickoutInfo] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlanMapLoaded, setIsPlanMapLoaded] = useState(false);
  const [pathCSS, setPathCSS] = useState("");
  const [segmentCSS, setSegmentCSS] = useState("");
  const [fixedViewBoxValue, setFixedViewBoxValue] = useState("0 0 0 0");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    get(`/v1/playbacks/${id}/maps`).then(
      (result) => {
        if (result.data && result.data.length > 0) {
          setMaps(result.data);
        } else {
          setError({ message: "Missing training map" });
        }
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get]);

  useEffect(() => {
    get(`/v1/plans/${planID}/maps`).then(
      (result) => {
        setPlanMap(result.data);
        setIsPlanMapLoaded(true);
      },
      () => {
        setIsPlanMapLoaded(true);
      }
    );
  }, [planID, get]);

  useEffect(() => {
    if (error || !isPlanMapLoaded || !isLoaded) {
      return;
    }
    var coords = maps.reduce((acc, val) => acc.concat(val.coordinates), []);

    let x_offset =
      coords.reduce((prev, cur) => (prev.x < cur.x ? prev : cur)).x - 1;
    let y_offset =
      coords.reduce((prev, cur) => (prev.y < cur.y ? prev : cur)).y - 1;

    const max_x_offset =
      coords.reduce((prev, cur) => (prev.x > cur.x ? prev : cur)).x + 1;
    const max_y_offset =
      coords.reduce((prev, cur) => (prev.y > cur.y ? prev : cur)).y + 1;
    const x_bound = max_x_offset - x_offset;
    const y_bound = max_y_offset - y_offset;

    if (planMap) {
      // use map to determine size of svg viewport
      setFixedViewBoxValue(`0 0 ${planMap.height} ${planMap.width}`);
      setMatrix(
        `matrix(${maps[0].matrix[0]} ${maps[0].matrix[1]} ${maps[0].matrix[2]} ${maps[0].matrix[3]} ${maps[0].matrix[4]} ${maps[0].matrix[5]})`
      );
      x_offset = 0;
      y_offset = 0;
    } else {
      // otherwise fill svg with the playback
      setFixedViewBoxValue(`0 0 ${x_bound} ${y_bound}`);
      setMatrix(null);
    }

    if (maps.length > 0) {
      const sortedMaps = maps.sort(
        (a, b) => a.playback_segment_index - b.playback_segment_index
      );
      const segs = sortedMaps.reduce((acc, m) => {
        const segmentFill = m.coordinates.reduce((b, c) => {
          const x = c.x - x_offset;
          const y = c.y - y_offset;
          return b + `${x}, ${y}, `;
        }, "M ");
        const id = `playback-segment-${m.playback_segment_index}`;
        acc.push(
          <path key={id} d={segmentFill} className="playback-path" id={id} />
        );
        return acc;
      }, []);
      setSegments(segs);

      const kickouts = sortedMaps
        .filter((_, i) =>
          i + 1 < sortedMaps.length ? !sortedMaps[i + 1].autoresumed : true
        )
        .reduce((acc, m) => {
          const last = m.coordinates[m.coordinates.length - 1];
          const id = `kickout-segment-${m.playback_segment_index}`;
          const err_msg = m.error_code ? m.error_message : "Completed";
          const err_code = m.error_code && m.error_code !== 0 ? m.err_code : "";
          const completedClass = err_msg === "Completed" ? "completed" : "";
          acc.push(
            <circle
              cx={last.x - x_offset}
              cy={last.y - y_offset}
              r=".3"
              className={`kickout ${completedClass}`}
              id={id}
              key={id}
              onMouseOver={(e) => {
                let currentTarget = document
                  .getElementsByClassName("container")[0]
                  .getBoundingClientRect();
                setKickoutInfo({
                  type: err_msg,
                  code: err_code,
                  time: m.end_time,
                  x: e.pageX - window.scrollX - currentTarget.left,
                  y: e.pageY - window.scrollY - currentTarget.top,
                  opacity: 1,
                });
              }}
              onMouseOut={() => {
                setKickoutInfo({
                  ...kickoutInfo,
                  opacity: 0,
                });
              }}
            />
          );
          return acc;
        }, []);
      setKickouts(kickouts);
      setIsLoaded(true);
    }
  }, [id, get, maps, planMap, kickoutInfo, isLoaded, isPlanMapLoaded, error]);

  useEffect(() => {
    let baseCSS = ".PlaybackMap-body  .playback-path";
    if (showPath) {
      setPathCSS(baseCSS + `{ stroke: black; stroke-width: 0.2 }`);
    } else {
      setPathCSS("");
    }
  }, [id, showPath]);

  const segmentSelect = (e) => {
    var val = e.target.value;
    if (
      document.querySelector(".PlaybackMap-content").querySelector("select")
        .value !== val
    ) {
      document
        .querySelector(".PlaybackMap-content")
        .querySelector("select").value = val;
    } else {
      document.querySelector(".modal").querySelector("select").value = val;
    }
    if (val === "kickouts") {
      setSegmentCSS(`
      .PlaybackMap-body .playback-path {
        stroke-opacity: 0.05;
      }
      .kickout {
        fill-opacity: 0.95
      }
      `);
    } else if (val === "0") {
      setSegmentCSS(`
      .PlaybackMap-body  .playback-path {
        stroke-opacity: 0.95;
      }
      .kickout {
        fill-opacity: 0.95
      }
      `);
    } else {
      setSegmentCSS(`
      .PlaybackMap-body  .playback-path  {
        stroke-opacity: 0.05;
      }
      .kickout {
        fill-opacity: 0.3
      }
      #playback-segment-${val - 1}{
        stroke-opacity: 0.95;
      }
      #kickout-segment-${val - 1}{
        fill-opacity: 0.95;
      }
      `);
    }
  };

  const openModal = () => {
    document.querySelector(".modal").classList.add("is-active");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    document.querySelector(".modal").classList.remove("is-active");
    setIsModalOpen(false);
  };

  let content;
  let segs;
  let tooltip;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded || !isPlanMapLoaded) {
    content = <LoadingIcon />;
  } else {
    tooltip = (
      <>
        {kickoutInfo && (
          <div
            data-testid="tooltip"
            className="tooltip-donut"
            style={{
              left: kickoutInfo.x + "px",
              top: kickoutInfo.y + "px",
              opacity: kickoutInfo.opacity,
              transition: "opacity .25s",
            }}
          >
            {kickoutInfo.type ? kickoutInfo.type : "Unknown"}
            {kickoutInfo.code && (
              <>
                <br /> Code: {kickoutInfo.code}
              </>
            )}
            <br />{" "}
            {new Date(kickoutInfo.time)
              .toString()
              .substring(
                0,
                new Date(kickoutInfo.time).toString().lastIndexOf("(") - 1
              )}
          </div>
        )}
      </>
    );
    segs = (
      <>
        {segments.map((x) => x)}
        {kickouts.map((x) => x)}
      </>
    );
    content = (
      <>
        <PanelMap
          id={id}
          segs={segs}
          matrix={matrix}
          planMap={planMap}
          maps={maps}
          fixedViewBoxValue={fixedViewBoxValue}
          tooltip={tooltip}
          openModal={openModal}
          segmentSelect={segmentSelect}
          segmentCSS={segmentCSS}
          pathCSS={pathCSS}
          showPath={showPath}
          togglePath={togglePath}
          wrapper={wrapper}
          hideButton={hideButton}
        ></PanelMap>
        <ModalMap
          id={id}
          segs={segs}
          matrix={matrix}
          planMap={planMap}
          maps={maps}
          fixedViewBoxValue={fixedViewBoxValue}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          segmentSelect={segmentSelect}
          segmentCSS={segmentCSS}
          pathCSS={pathCSS}
          showPath={showPath}
          togglePath={togglePath}
        ></ModalMap>
      </>
    );
  }
  return <>{content}</>;
};

export default PlaybackMap;
