import "./style.css";
import { React, useState } from "react";

const MultiselectDropdown = ({
  title,
  items = [],
  selected = [],
  handleSelect = () => {},
}) => {
  const [active, setActive] = useState(false);

  // Solution: https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    // blur happens before click, preventing any click events in children from firing due to rerender from state change
    // so wait a tick for child component events to fire before changing open state and causing rerender
    window.setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setActive(false);
      }
    }, 100);
  };
  return (
    <div
      className={`MultiselectDropdown dropdown ${active ? "is-active" : ""}`}
      onBlur={handleBlur}
    >
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => setActive(!active)}
        >
          <span>{title}</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {items.map((item, i) => (
            <div key={i} className="dropdown-item">
              <label className="checkbox">
                <input
                  type="checkbox"
                  onChange={() => handleSelect(item.id)}
                  checked={selected.some((v) => v === item.id)}
                />
                {item.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default MultiselectDropdown;
