import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import { useHistory, useLocation } from "react-router-dom";
import Moment from "react-moment";

import PlaybackList from "../../../domain/Plan/PlaybackList";
import PlanMap from "../../../domain/Plan/PlanMap";
import ViewHeader from "../../../components/ViewHeader";
import StatsHeader from "../../../domain/Plan/StatsHeader";
import LoadingIcon from "../../../components/LoadingIcon";
import RuntimeChart from "../../../domain/Plan/RuntimeChart";
import ErrorIcon from "../../../components/ErrorIcon";
import KickoutChart from "../../../domain/Plan/KickoutChart";
import UpdateNameModal from "../../../domain/Plan/UpdateNameModal";
import { RoleContext } from "../../../services/Api/Role";
import moment from "moment";

const ViewPlan = ({ match }) => {
  const { role } = useContext(RoleContext);
  const {
    params: { id },
  } = match;

  const query = new URLSearchParams(useLocation().search);
  const sd = query.get("start_date")
    ? moment(query.get("start_date")).startOf("day")
    : null;
  const ed = query.get("end_date")
    ? moment(query.get("end_date")).endOf("day")
    : null;

  const [plan, setPlan] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [startDate, setStartDate] = useState(sd);
  const [endDate, setEndDate] = useState(ed);

  const { get } = useContext(AuthClientContext);

  const history = useHistory();
  const rowClick = (rowid) => {
    history.push({
      pathname: `/playbacks/${rowid}`,
    });
  };

  const kickoutRowClick = (id) => {
    history.push(`/troubleshoot/${id}`);
  };

  useEffect(() => {
    get(`/v1/plans/${id}`).then(
      (result) => {
        setPlan(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get, modalOpen]);

  useEffect(() => {
    let params = {};
    if (startDate) {
      params["start_date"] = startDate.format("YYYY-MM-DD");
    }
    if (endDate) {
      params["end_date"] = endDate.format("YYYY-MM-DD");
    }
    history.replace({
      pathname: `/plans/${id}`,
      search: "?" + new URLSearchParams(params).toString(),
    });
  }, [history, id, startDate, endDate]);

  const isAdmin = role === "superadmin" || role === "admin";
  const edit = isAdmin ? () => setModalOpen(true) : null;

  if (error) {
    return <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    return <LoadingIcon />;
  } else {
    return (
      <div>
        <ViewHeader id={plan.name} name="Plan" edit={edit} />
        <div className="columns is-multiline">
          <div className="column is-12">
            <StatsHeader
              id={id}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
          <div className="column is-12">
            <PlaybackList
              id={id}
              type="plan_id"
              rowClick={rowClick}
              fieldTitles={["Machine", "Version"]}
              fields={["buddy_name", "compute_version"]}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="column is-5">
            <PlanMap
              id={id}
              startDate={startDate}
              endDate={endDate}
              isFillIn={plan.plan_type === "InFill"}
            />
            <RuntimeChart id={id} startDate={startDate} endDate={endDate} />
            <div data-testid="metadata-box" className="box metadata">
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Last Updated:
                </span>
                <span>
                  <Moment format="YYYY-MM-DD" utc local>
                    {plan.updated_at}
                  </Moment>
                </span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Plan Type:
                </span>
                <span>{plan.plan_type}</span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Training Distance:
                </span>
                <span>{plan.training_distance_m.toFixed(2) + " m"}</span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Training Time:
                </span>
                <span>
                  {plan.training_time_seconds
                    ? (plan.training_time_seconds / 60).toFixed(2) + " minutes"
                    : "-"}
                </span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Location Tag:
                </span>
                <span>{plan.april_tag}</span>
              </div>
            </div>
          </div>
          <div className="column is-7">
            <KickoutChart
              id={id}
              rowClick={kickoutRowClick}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <UpdateNameModal
            id={id}
            name={plan.name}
            siteName={plan.site_name}
            opened={modalOpen}
            close={() => {
              setModalOpen(false);
            }}
          />
        </div>
      </div>
    );
  }
};

export default ViewPlan;
