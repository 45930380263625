import React from "react";

const PanelMap = ({
  id,
  segs,
  matrix,
  planMap,
  maps,
  fixedViewBoxValue,
  tooltip,
  openModal,
  segmentSelect,
  segmentCSS,
  pathCSS,
  showPath,
  togglePath,
  wrapper = true,
  hideButton = false,
}) => {
  let icon = showPath ? "fa-tint" : "fa-draw-polygon";
  var buttonText = showPath ? "Show Cleaned" : "Show Recorded Plan";

  let img;
  let buttonContent;
  let content;
  if (planMap) {
    img = <image href={planMap.map_image_url} height={planMap.width} />;
  }
  buttonContent = (
    <>
      <div
        data-testid="panel-toggle-path"
        className="button is-light is-info is-pulled-right map-button"
        onClick={() => togglePath(!showPath)}
      >
        <i className={["fas " + icon]} />
        {buttonText}
      </div>
    </>
  );
  content = (
    <>
      <div className="columns is-centered">
        <div className="select is-rounded">
          <select
            data-testid="panel-segment-select"
            onChange={(e) => segmentSelect(e)}
          >
            <option value="0">All Segments</option>
            {maps.map((x, i) => (
              <option key={i} value={x.playback_segment_index + 1}>
                Segment {x.playback_segment_index + 1}
              </option>
            ))}
            <option value="kickouts">Kickouts</option>
          </select>
        </div>
      </div>
    </>
  );
  const inner = (
    <div className="PlaybackMap-content">
      <div className="PlaybackMap-body">
        {!hideButton && (
          <div
            data-testid="expand-modal-map"
            className="button is-pulled-left"
            onClick={() => openModal()}
          >
            <i className="fa fa-search-plus" />
          </div>
        )}
        <div className="PlaybackMap-button">{buttonContent}</div>
        <div className={"PlaybackMap-image" + id}>
          <style>{pathCSS}</style>
          <style>{segmentCSS}</style>
          <svg
            id="playback-map-svg-parent"
            data-testid="panel-playback-image"
            width="100%"
            height="700"
            viewBox={fixedViewBoxValue}
          >
            {img}
            <g transform={matrix}>{segs}</g>
          </svg>
          {content}
        </div>
      </div>
      {tooltip}
    </div>
  );
  return wrapper ? (
    <div className="PlaybackMap-panel">
      <div className="PlaybackMap-heading">Playback Path</div>
      {inner}
    </div>
  ) : (
    <>{inner}</>
  );
};

export default PanelMap;
