const CLIENT_ID = process.env.CLIENT_ID || "0oa4dfedwiPxNaTzE5d6";
const ISSUER = process.env.ISSUER || "https://thoro.okta.com/oauth2/default";
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI =
  process.env.REACT_APP_REDIRECT_URI || `http://localhost:3000/login/callback`;
const oidc = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
};

const oktaSignInConfig = {
  baseUrl: "https://thoro.okta.com",
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  logo: "//customer.thoro.ai/images/thoro-logo.png",
  idps: [{ type: "google", id: "0oamz0vuvEXWjyPhR5d6" }],
  authParams: {
    issuer: ISSUER,
    responseType: ["id_token", "token"],
    scopes: ["openid", "email", "profile"],
  },
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
};

export { oidc, oktaSignInConfig };
