import "./style.css";
import React, { useState, useEffect } from "react";

const ModalMap = ({
  id,
  segs,
  matrix,
  planMap,
  maps,
  fixedViewBoxValue,
  isModalOpen,
  closeModal,
  segmentSelect,
  segmentCSS,
  pathCSS,
  showPath,
  togglePath,
}) => {
  const [svgSize, setSVGSize] = useState({});
  const [viewBoxValue, setViewBoxValue] = useState("0 0 0 0");
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [originViewBox, setOriginViewBox] = useState({});
  const [pointerOrigin, setPointerOrigin] = useState({});
  const [modalSVGHeight, setModalSVGHeight] = useState("0");
  const [svg, setSVG] = useState(null);

  useEffect(() => {
    let svg = document.querySelector(".modal").querySelector("svg");
    svg.addEventListener("wheel", (e) => e.preventDefault(), {
      passive: false,
    });
    setSVG(svg);
  }, []);

  useEffect(() => {
    if (isModalOpen && svg) {
      setViewBoxValue(fixedViewBoxValue);
      let modal = document.querySelector(".modal-content");
      let body = document
        .querySelector(".modal")
        .querySelector(".PlaybackMap-body");
      let padding = window
        .getComputedStyle(body, null)
        .getPropertyValue("padding-top");
      let paddingHeight = parseFloat(padding.substring(0, padding.length - 2));

      let nav = document.querySelector(".modal").querySelector("nav");
      let navMargin = window
        .getComputedStyle(nav, null)
        .getPropertyValue("margin-bottom");
      let navHeight =
        nav.getBoundingClientRect().height +
        parseFloat(navMargin.substring(0, navMargin.length - 2));

      let height =
        modal.getBoundingClientRect().height - paddingHeight - navHeight;
      setModalSVGHeight(`${height}`);
      setSVGSize({ w: svg.clientWidth, h: height });
    }
  }, [fixedViewBoxValue, svg, isModalOpen]);

  const onMouseWheel = (e) => {
    e = e.nativeEvent;
    let viewBox = {
      x: parseFloat(svg.getAttribute("viewBox").split(" ")[0]),
      y: parseFloat(svg.getAttribute("viewBox").split(" ")[1]),
      width: parseFloat(svg.getAttribute("viewBox").split(" ")[2]),
      height: parseFloat(svg.getAttribute("viewBox").split(" ")[3]),
    };
    let mx = e.offsetX; //mouse x
    let my = e.offsetY;
    let dw = viewBox.width * Math.sign(e.deltaY) * 0.05;
    let dh = viewBox.height * Math.sign(e.deltaY) * 0.05;
    let dx = (dw * mx) / svgSize.w;
    let dy = (dh * my) / svgSize.h;
    setViewBoxValue(
      `${viewBox.x + dx} ${viewBox.y + dy} ${viewBox.width - dw} ${
        viewBox.height - dh
      }`
    );
  };

  const onPointerDown = (event) => {
    setIsPointerDown(true);
    event.target.style.cursor = "grabbing";
    setOriginViewBox({
      x: parseFloat(svg.getAttribute("viewBox").split(" ")[0]),
      y: parseFloat(svg.getAttribute("viewBox").split(" ")[1]),
      width: parseFloat(svg.getAttribute("viewBox").split(" ")[2]),
      height: parseFloat(svg.getAttribute("viewBox").split(" ")[3]),
    });
    setPointerOrigin({ x: event.clientX, y: event.clientY });
  };

  const onPointerUp = (event) => {
    setIsPointerDown(false);
    event.target.style.cursor = "";
  };

  const onPointerMove = (event) => {
    if (!isPointerDown) {
      return;
    }
    let scale = svgSize.w / originViewBox.width;
    let changeX = (event.clientX - pointerOrigin.x) / scale;
    let changeY = (event.clientY - pointerOrigin.y) / scale;
    setViewBoxValue(
      `${originViewBox.x - changeX} ${originViewBox.y - changeY} ${
        originViewBox.width
      } ${originViewBox.height}`
    );
  };

  let icon = showPath ? "fa-tint" : "fa-draw-polygon";
  var buttonText = showPath ? "Show Cleaned" : "Show Recorded Plan";

  let img;
  let buttonContent;
  let content;
  if (planMap) {
    img = <image href={planMap.map_image_url} height={planMap.width} />;
  }
  buttonContent = (
    <>
      <div
        data-testid="modal-toggle-path"
        className="button is-light is-info is-pulled-right map-button"
        onClick={() => togglePath(!showPath)}
      >
        <i className={["fas " + icon]} />
        {buttonText}
      </div>
    </>
  );
  content = (
    <>
      <div className="columns is-centered">
        <div className="select is-rounded">
          <select
            data-testid="modal-segment-select"
            onChange={(e) => segmentSelect(e)}
          >
            <option value="0">All Segments</option>
            {maps.map((x, i) => (
              <option key={i} value={x.playback_segment_index + 1}>
                Segment {x.playback_segment_index + 1}
              </option>
            ))}
            <option value="kickouts">Kickouts</option>
          </select>
        </div>
      </div>
    </>
  );

  return (
    <div className="modal">
      <div
        data-testid="modal-background"
        className="modal-background"
        onClick={(e) => closeModal()}
      ></div>
      <div className="modal-content">
        <div className="PlaybackMap-body">
          <nav className="level">
            <div className="level-item">{content}</div>
            <div className="level-right">
              <div className="level-item">
                <div className="PlaybackMap-button">{buttonContent}</div>
              </div>
            </div>
          </nav>
          <div className={"PlaybackMap-image" + id}>
            <style>{pathCSS}</style>
            <style>{segmentCSS}</style>
            <svg
              onPointerDown={(e) => onPointerDown(e)}
              onPointerUp={(e) => onPointerUp(e)}
              onPointerMove={(e) => onPointerMove(e)}
              onMouseLeave={(e) => onPointerUp(e)}
              onWheel={(e) => onMouseWheel(e)}
              id="playback-map-svg-parent"
              data-testid="modal-playback-image"
              width="100%"
              height={modalSVGHeight}
              viewBox={viewBoxValue}
            >
              {img}
              <g transform={matrix}>{segs}</g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalMap;
