import React from "react";
import TroubleshootList from "../../../domain/Troubleshoot/TroubleshootList";
import { useHistory } from "react-router-dom";

const ListTroubleshoots = () => {
  const history = useHistory();

  const rowClick = (id) => {
    history.push(`/troubleshoot/${id}`);
  };

  return (
    <div>
      <h1 className="title">Troubleshoot Messages</h1>
      <div className="columns is-centered">
        <div className="box column is-12">
          <TroubleshootList rowClick={rowClick} />
        </div>
      </div>
    </div>
  );
};

export default ListTroubleshoots;
