import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";

import { useOktaAuth } from "@okta/okta-react";
import { oktaSignInConfig } from "../../config";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

const OktaSignInWidget = () => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) return false;

    const widget = new OktaSignIn(oktaSignInConfig);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then((res) => {
        const sessionToken = res.sessionToken;
        oktaAuth.signInWithRedirect({ sessionToken });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
      });

    return () => widget.remove();
  }, [oktaAuth]);

  return <div ref={widgetRef} />;
};
export default OktaSignInWidget;
