import "./style.css";

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { AuthClientContext } from "../../services/Api/AuthClient";

import moment from "moment";
import LoadingIcon from "../../components/LoadingIcon";

const Home = () => {
  const [devices, setDevices] = useState([]);
  const [activeDevices, setActiveDevices] = useState([]);
  const [inactiveDevices, setInactiveDevices] = useState([]);
  const [aggregateMetrics, setAggregateMetrics] = useState(null);
  const [topPerformers, setTopPerformers] = useState([]);
  const [lowPerformers, setLowPerformers] = useState([]);
  const [avgDaily, setAvgDaily] = useState(null);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [isDeviceLoaded, setIsDeviceLoaded] = useState(false);
  const [isPerfLoaded, setIsPerfLoaded] = useState(false);
  const [isAvgDailyLoaded, setIsAvgDailyLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    const dateFrom = moment().subtract(7, "d").startOf("day");
    get(`/v1/metrics/dashboard/daily_aggregate`, {
      params: { start_date: dateFrom.format() },
    }).then(
      (result) => {
        const data = result.data.reduce(
          (p, n) => {
            return {
              total_area_cleaned: p.total_area_cleaned + n.total_area_cleaned,
              total_runtime: p.total_runtime + n.total_runtime,
              total_runs: p.total_runs + n.total_runs,
              total_kickouts:
                p.total_kickouts + (n.total_kickouts - n.total_autoresumed),
            };
          },
          {
            total_area_cleaned: 0,
            total_runtime: 0,
            total_kickouts: 0,
            total_runs: 0,
          }
        );
        setAvgDaily(data);
        setAggregateMetrics(data);
        setIsAvgDailyLoaded(true);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsAvgDailyLoaded(true);
        setIsLoaded(true);
      }
    );
  }, [get]);

  useEffect(() => {
    const dateFrom = moment().subtract(7, "d").startOf("day");
    get(`/v1/metrics/dashboard/performers`, {
      params: { start_date: dateFrom.format() },
    }).then(
      (result) => {
        const data = result.data;
        const top = data
          .sort((a, b) => (a.total_runtime > b.total_runtime ? -1 : 1))
          .slice(0, Math.min(5, data.length));
        const low = data
          .sort((a, b) => (a.total_runtime < b.total_runtime ? -1 : 1))
          .slice(0, Math.min(5, data.length));
        setTopPerformers(top);
        setLowPerformers(low);
        setActiveDevices(result.data);
        setIsPerfLoaded(true);
      },
      (error) => {
        setError(error);
        setIsPerfLoaded(true);
      }
    );
  }, [get]);

  useEffect(() => {
    get(`/v1/devices/`).then(
      (result) => {
        setDevices(result.data);
        setIsDeviceLoaded(true);
      },
      (error) => {
        setError(error);
        setIsDeviceLoaded(true);
      }
    );
  }, [get]);

  useEffect(() => {
    setInactiveDevices(devices.length - activeDevices.length);
  }, [devices, activeDevices]);
  return (
    <div>
      <h1 key="dashboard-title" className="title">
        Dashboard
      </h1>
      {error ? <div class="notification is-danger">{error}</div> : null}
      <div className="columns is-multiline">
        <div className="column is-2 level-item has-text-centered">
          <div className="box">
            <p className="heading is-size-6">Total Machines</p>
            <div data-testid="total-runtime" className="title is-size-2">
              {isDeviceLoaded ? devices.length : <LoadingIcon />}
            </div>
          </div>
        </div>
        <div className="column is-4 level-item has-text-centered">
          <div className="box">
            <p className="heading is-size-6">
              Weekly Autonomous Runtime (Hours)
            </p>
            <div data-testid="total-runtime" className="title is-size-2">
              {isLoaded ? (
                moment
                  .duration(aggregateMetrics.total_runtime, "seconds")
                  .format("hh:mm")
              ) : (
                <LoadingIcon />
              )}
            </div>
          </div>
        </div>
        <div className="column is-4 level-item has-text-centered">
          <div className="box">
            <p className="heading is-size-6">Weekly Area Cleaned (m&sup2;)</p>
            <div data-testid="total-runtime" className="title is-size-2">
              {isLoaded ? (
                aggregateMetrics.total_area_cleaned ? (
                  aggregateMetrics.total_area_cleaned.toLocaleString("en", {
                    style: "decimal",
                    maximumFractionDigits: 1,
                  })
                ) : (
                  0
                )
              ) : (
                <LoadingIcon />
              )}
            </div>
          </div>
        </div>
        <div className="column is-2 level-item has-text-centered">
          <div className="box">
            <p className="heading is-size-6">Plans Executed</p>
            <div data-testid="total-runtime" className="title is-size-2">
              {isLoaded ? (
                aggregateMetrics.total_runs.toLocaleString("en", {
                  style: "decimal",
                  maximumFractionDigits: 1,
                })
              ) : (
                <LoadingIcon />
              )}
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div className="level-item has-text-centered">
            <div className="box" style={{ width: "100%" }}>
              <p className="heading is-size-6">
                Avg Area Cleaned <br />
                (Past Week)
              </p>
              <div data-testid="total-runtime" className="title is-size-2">
                {isAvgDailyLoaded ? (
                  <>
                    {(
                      avgDaily.total_area_cleaned / activeDevices.length
                    ).toLocaleString("en", {
                      style: "decimal",
                      maximumFractionDigits: 1,
                    })}{" "}
                    m&sup2;
                  </>
                ) : (
                  <LoadingIcon />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div className="level-item has-text-centered">
            <div className="box" style={{ width: "100%" }}>
              <p className="heading is-size-6">
                Avg Runtime (Hours)
                <br />
                (Past Week)
              </p>
              <div data-testid="total-runtime" className="title is-size-2">
                {isAvgDailyLoaded ? (
                  <>
                    {moment
                      .duration(
                        // avgDaily.runtime_seconds / avgDaily.active_machines,
                        avgDaily.total_runtime / activeDevices.length,
                        "seconds"
                      )
                      .format("hh:mm", { trim: false })}
                  </>
                ) : (
                  <LoadingIcon />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="level-item has-text-centered mb-5">
            <div className="box" style={{ width: "100%" }}>
              <p className="heading is-size-6">
                Total Active Machines
                <br />
                (Past Week)
              </p>
              <div data-testid="total-runtime" className="title is-size-2">
                {isDeviceLoaded ? activeDevices.length : <LoadingIcon />}
              </div>
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="level-item has-text-centered mb-5">
            <div className="box" style={{ width: "100%" }}>
              <p className="heading is-size-6">
                Total Inactive Machines
                <br />
                (Past Week)
              </p>
              <div data-testid="total-runtime" className="title is-size-2">
                {isDeviceLoaded ? inactiveDevices : <LoadingIcon />}
              </div>
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="level-item has-text-centered mb-5">
            <div className="box" style={{ width: "100%" }}>
              <p className="heading is-size-6">
                Kickouts per Hour
                <br />
                (Past Week)
              </p>
              <div data-testid="total-runtime" className="title is-size-2">
                {isLoaded ? (
                  (
                    aggregateMetrics.total_kickouts /
                    (aggregateMetrics.total_runtime / 3600)
                  ).toFixed(1)
                ) : (
                  <LoadingIcon />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div className="chart-table">
            <div className="chart-heading">Top Performers (7 Days)</div>
            <div className="chart-body" style={{ height: "250px" }}>
              {isPerfLoaded ? (
                <table className="table is-hoverable">
                  <thead>
                    <tr>
                      <th>Machine</th>
                      <th>Total Runtime</th>
                      <th>Total Area Cleaned</th>
                      <th>K/H</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topPerformers.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <Link to={`/devices/${item.device}`}>
                            {item.device_name}
                          </Link>
                        </td>
                        <td>
                          {moment
                            .duration(item.total_runtime, "seconds")
                            .format("hh:mm", { trim: false })}
                        </td>
                        <td>{item.total_area_cleaned.toFixed(1)}</td>
                        <td>
                          {(
                            item.total_kickouts /
                            (item.total_runtime / 3600)
                          ).toFixed(1)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <LoadingIcon />
              )}
            </div>
          </div>
        </div>
        <div className="column is-6">
          <div className="chart-table">
            <div className="chart-heading">Lowest Performers (7 Days)</div>
            <div className="chart-body" style={{ height: "250px" }}>
              {isPerfLoaded ? (
                <table className="table is-hoverable">
                  <thead>
                    <tr>
                      <th>Machine</th>
                      <th>Total Runtime</th>
                      <th>Total Area Cleaned</th>
                      <th>K/H</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lowPerformers.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <Link to={`/devices/${item.device}`}>
                            {item.device_name}
                          </Link>
                        </td>
                        <td>
                          {moment
                            .duration(item.total_runtime, "seconds")
                            .format("hh:mm", { trim: false })}
                        </td>
                        <td>{item.total_area_cleaned.toFixed(1)}</td>
                        <td>
                          {(
                            item.total_kickouts /
                            (item.total_runtime / 3600)
                          ).toFixed(1)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <LoadingIcon />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
