import "./style.css";

import React, { useContext } from "react";

import { RoleContext } from "../../services/Api/Role";
import LandingLink from "../../components/Settings/LandingLink";

const Report = () => {
  const { role } = useContext(RoleContext);

  return (
    <div className="columns is-multiline">
      <LandingLink name="Machines" link="/reports/devices" icon="fa-robot" />
      <LandingLink name="Runs" link="/reports/runs" icon="fa-running" />
      <LandingLink name="Plans" link="/reports/plan-devices" icon="fa-map" />
      {role === "superadmin" ? (
        <LandingLink
          name="Kickouts"
          link="/reports/kickouts"
          icon="fa-exclamation-triangle"
        />
      ) : undefined}
    </div>
  );
};

export default Report;
