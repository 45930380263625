import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import Moment from "react-moment";

import PlaybackMap from "../../../domain/Playback/PlaybackMap";
import ViewHeader from "../../../components/ViewHeader";
import StatsHeader from "../../../domain/Playback/StatsHeader";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import { useHistory } from "react-router-dom";
import moment from "moment";
const ViewPlayback = ({ match }) => {
  const {
    params: { id },
  } = match;

  const [playback, setPlayback] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { get } = useContext(AuthClientContext);

  const history = useHistory();

  useEffect(() => {
    get(`/v1/metrics/scrubber/playback/${id}`).then(
      (result) => {
        setPlayback(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get]);

  const deviceClick = (id) => {
    history.push(`/devices/${id}`);
  };

  if (error) {
    return <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    return <LoadingIcon />;
  } else {
    return (
      <div>
        <ViewHeader name="Playback" id={playback.id} />
        <div className="columns is-multiline">
          <div className="column is-12">
            <StatsHeader id={id} />
          </div>
          <div className="column is-5">
            <div data-testid="metadata-box" className="metadata box">
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Start Time:
                </span>
                <span>
                  <Moment format="YYYY-MM-DD HH:mm" utc local>
                    {playback.start_time}
                  </Moment>
                </span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  End Time:
                </span>
                <span>
                  <Moment format="YYYY-MM-DD HH:mm" utc local>
                    {playback.end_time}
                  </Moment>
                </span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Idle Time (min):
                </span>
                <span>
                  {(
                    (playback.elapsed_time -
                      moment
                        .duration(
                          moment(playback.end_time).diff(
                            moment(playback.start_time)
                          )
                        )
                        .seconds()) /
                    60
                  ).toFixed(1)}
                </span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Machine:
                </span>
                <span>
                  <span
                    id="deviceLink"
                    data-testid="device-link"
                    onClick={() => deviceClick(playback.device)}
                  >
                    {playback.device_alias !== ""
                      ? playback.device_alias
                      : playback.device}
                  </span>
                </span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Plan:
                </span>
                <span>
                  <a
                    data-testid="plan-link"
                    href={"/plans/" + playback.plan_uuid}
                  >
                    {playback.plan_name}
                  </a>
                </span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Location Tag:
                </span>
                <span>{playback.april_tag_id}</span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Release:
                </span>
                <span>
                  {playback.software_version &&
                  playback.software_version.version
                    ? playback.software_version.version
                    : "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="column is-7">
            <PlaybackMap id={id} planID={playback.plan_uuid} />
          </div>
        </div>
      </div>
    );
  }
};

export default ViewPlayback;
