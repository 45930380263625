import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";

import moment from "moment";

const StatsHeader = ({ id }) => {
  const [metrics, setMetrics] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    get(`/v1/metrics/scrubber/playback/${id}`).then(
      (result) => {
        setMetrics(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get]);

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Runtime</p>
            <p className="title">
              {moment
                .duration(metrics.elapsed_time, "seconds")
                .format("hh:mm", { trim: false })}
            </p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Kickouts</p>
            <p className="title">
              {metrics.total_kickouts > metrics.autoresumed_count
                ? (metrics.total_kickouts - metrics.autoresumed_count).toFixed(
                    0
                  )
                : 0}
            </p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Area Cleaned (M2)</p>
            <p className="title">{metrics.total_cleaned_area_m2.toFixed(1)}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Coverage Rate (M2/Hr)</p>
            <p className="title">
              {metrics.elapsed_time !== 0
                ? (
                    metrics.total_cleaned_area_m2 /
                    (metrics.elapsed_time / 3600)
                  ).toFixed(0)
                : 0}
            </p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">UVGI (J)</p>
            <p className="title">-</p>
          </div>
        </div>
      </>
    );
  }
  return (
    <nav
      data-testid="playback-stats-header"
      className="level box"
      role="banner"
    >
      {content}
    </nav>
  );
};

export default StatsHeader;
