import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import DatePicker from "../../../components/DatePicker";

import moment from "moment";

const StatsHeader = ({ id, startDate, endDate, setStartDate, setEndDate }) => {
  const [metrics, setMetrics] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    let params = {};
    if (startDate) {
      params["start_date"] = startDate.format();
    }
    if (endDate) {
      params["end_date"] = endDate.format();
    }
    get(`/v1/metrics/scrubber/plans/${id}/overview`, { params: params }).then(
      (result) => {
        setMetrics(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get, startDate, endDate]);

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Avg Daily Autonomous Runtime (h)</p>
            <p className="title">
              {moment
                .duration(
                  metrics.total_runtime / metrics.max_days_of_activation,
                  "seconds"
                )
                .format("hh:mm", { trim: false })}
            </p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Average Daily Area Cleaned (m&sup2;)</p>
            <p className="title">
              {(
                metrics.total_area_cleaned / metrics.max_days_of_activation
              ).toFixed(1)}
            </p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Times Run</p>
            <p className="title">{metrics.total_runs}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Average Kickouts (Per Hour)</p>
            <p className="title">
              {(
                metrics.total_kickouts /
                (metrics.total_runtime / 3600)
              ).toFixed(2)}
            </p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">UVGI (J)</p>
            <p className="title">-</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <DatePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
      </>
    );
  }
  return (
    <nav data-testid="device-stats-header" className="level box" role="banner">
      {content}
    </nav>
  );
};

export default StatsHeader;
