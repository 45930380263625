import "./style.css";
import React, { useState, useContext } from "react";
import { AuthClientContext } from "../../../../services/Api/AuthClient";
import { useHistory } from "react-router-dom";

const CreateOrganization = () => {
  const [error, setError] = useState(null);
  const [org, setOrg] = useState({
    name: "",
    alias: "",
    initial_user: { first_name: "", last_name: "", email_address: "" },
  });

  const history = useHistory();
  const { post } = useContext(AuthClientContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    post(`admin/v1/organizations`, org).then(
      () => {
        history.push(`/organizations`);
      },
      (error) => {
        setError(error);
      }
    );
  };
  return (
    <>
      <h1 className="title">Create Organization</h1>
      {error ? (
        <div className="notification is-danger is-light">{error}</div>
      ) : null}
      <div className="columns">
        <div className="column is-half">
          <form className="form box" onSubmit={(e) => handleSubmit(e)}>
            <div className="field">
              <label className="label">Company Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  onChange={(e) => {
                    let o = { ...org };
                    o.name = e.target.value;
                    setOrg(o);
                  }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Alias</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  onChange={(e) => {
                    let o = { ...org };
                    o.alias = e.target.value;
                    setOrg(o);
                  }}
                />
              </div>
            </div>
            <hr />
            <h3 className="title is-5">Initial Administrator</h3>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  onChange={(e) => {
                    let o = { ...org };
                    o.initial_user.email_address = e.target.value;
                    setOrg(o);
                  }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">First Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  onChange={(e) => {
                    let o = { ...org };
                    o.initial_user.first_name = e.target.value;
                    setOrg(o);
                  }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Last Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  onChange={(e) => {
                    let o = { ...org };
                    o.initial_user.last_name = e.target.value;
                    setOrg(o);
                  }}
                />
              </div>
            </div>
            <hr />
            <button type="submit" className="button is-success">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateOrganization;
