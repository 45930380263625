import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";

import lunr from "lunr";
import SortTableHeader from "../../../components/SortTableHeader";

const createIdx = (values) => {
  return lunr(function () {
    this.ref("id");
    this.field("display_name");
    this.field("april_tag");

    values.forEach((doc) => {
      this.add(doc);
    }, this);
  });
};

const PlanList = ({ rowClick }) => {
  const [list, setList] = useState([]);

  const [idx, setIdx] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [sort, setSort] = useState(null);
  const [desc, setDesc] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    get("/v1/metrics/scrubber/plans").then(
      (result) => {
        setList(result.data);
        setFilteredList(result.data);
        setIdx(createIdx(result.data));
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [get]);

  const search = (val) => {
    const res = idx.search(`*${val}*`);
    setFilteredList(list.filter((v) => res.some((r) => r.ref === v.id)));
  };

  const clickSort = (val) => {
    if (val === sort) {
      setDesc(!desc);
    } else {
      setSort(val);
      setDesc(true);
    }
    setFilteredList(
      filteredList.sort((a, b) => {
        const o = desc ? 1 : -1;
        if (a[val] < b[val]) return -1 * o;
        if (a[val] > b[val]) return 1 * o;
        return 0;
      })
    );
  };

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <>
        <div className="columns">
          <div className="field column is-offset-9 is-3 ">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input"
                type="text"
                placeholder="Search"
                onChange={(evt) => search(evt.target.value)}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-search"></i>
              </span>
            </p>
          </div>
        </div>
        <table data-testid="plan-table" className="table PlanList is-hoverable">
          <thead>
            <tr>
              <SortTableHeader
                name="Name"
                field="name"
                sort={sort}
                desc={desc}
                clickHandler={() => clickSort("name")}
              />
              <SortTableHeader
                name="Tag"
                field="april_tag"
                sort={sort}
                desc={desc}
                clickHandler={() => clickSort("april_tag")}
              />
            </tr>
          </thead>
          <tbody>
            {filteredList.map((item) => (
              <tr key={item.id} onClick={() => rowClick(item.id)}>
                <td className="link-text">{item.display_name}</td>
                <td>{item.april_tag}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  return <div className="column is-12">{content}</div>;
};

export default PlanList;
