import "./style.css";
import React, { useState, useEffect, useContext } from "react";

import { AuthClientContext } from "../../../services/Api/AuthClient";

import { RoleContext } from "../../../services/Api/Role";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import moment from "moment";
import lunr from "lunr";
import SortTableHeader from "../../../components/SortTableHeader";
import { Link } from "react-router-dom";

const createIdx = (values) => {
  return lunr(function () {
    this.ref("device");
    this.field("device_alias");
    this.field("device_serial_number");

    values.forEach((doc) => {
      this.add(doc);
    }, this);
  });
};

const DeviceList = ({ rowClick }) => {
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [sort, setSort] = useState("device_alias");
  const [desc, setDesc] = useState(false);
  const [idx, setIdx] = useState(null);
  const [error, setError] = useState(null);
  const [isMetricsLoaded, setIsMetricsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);
  const { special } = useContext(RoleContext);

  useEffect(() => {
    get(`/v1/metrics/scrubber/report/devices`, {
      params: { sortBy: `${sort}:${desc ? "desc" : "asc"}` },
    }).then(
      (result) => {
        setList(result.data);
        setFilteredList(result.data);
        setIdx(createIdx(result.data));
        setIsMetricsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsMetricsLoaded(true);
      }
    );
  }, [get, sort, desc]);

  const search = (val) => {
    const res = idx.search(`${val}*`);
    setFilteredList(list.filter((v) => res.some((r) => r.ref === v.id)));
  };

  const clickSort = (val) => {
    if (val === sort) {
      setDesc(!desc);
    } else {
      setSort(val);
      setDesc(true);
    }
  };

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isMetricsLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <>
        <div className="columns">
          <div className="field column is-offset-9 is-3 ">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input"
                type="text"
                placeholder="Search"
                onChange={(evt) => search(evt.target.value)}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-search"></i>
              </span>
            </p>
          </div>
        </div>
        <table
          data-testid="device-table"
          className="table DeviceList is-hoverable"
        >
          <thead>
            <tr>
              <SortTableHeader
                name="Name"
                field="device_alias"
                sort={sort}
                desc={desc}
                clickHandler={() => clickSort("device_alias")}
              />
              <SortTableHeader
                name="Customer"
                field="tenant_name"
                sort={sort}
                desc={desc}
                clickHandler={() => clickSort("tenant_name")}
              />
              {special ? (
                <SortTableHeader
                  name="Autonomy Kit"
                  field="id"
                  sort={sort}
                  desc={desc}
                  clickHandler={() => clickSort("id")}
                />
              ) : null}
              <SortTableHeader
                name="Serial Number"
                field="serial_number"
                sort={sort}
                desc={desc}
                clickHandler={() => clickSort("serial_number")}
              />
              <SortTableHeader
                name="Total Runtime"
                field="total_runtime"
                sort={sort}
                desc={desc}
                clickHandler={() => clickSort("total_runtime")}
              />
              <SortTableHeader
                name="Total Area Cleaned"
                field="total_cleaned_area_m2"
                sort={sort}
                desc={desc}
                clickHandler={() => clickSort("total_cleaned_area_m2")}
              />
              <SortTableHeader
                name="Total Kickouts"
                field="total_kickouts"
                sort={sort}
                desc={desc}
                clickHandler={() => clickSort("total_kickouts")}
              />
            </tr>
          </thead>
          <tbody>
            {filteredList.map((item, i) => (
              <tr key={i}>
                <td className="link-text">
                  <Link to={`/devices/${item.device}`}>
                    {item.device_alias ? item.device_alias : item.device}
                  </Link>
                </td>
                <td>{item.tenant_name}</td>
                {special ? <td>{item.device}</td> : null}
                <td>
                  {item.device_serial_number !== ""
                    ? item.device_serial_number
                    : "-"}
                </td>
                <td>
                  {item.elapsed_time
                    ? moment
                        .duration(item.elapsed_time, "seconds")
                        .format("hh:mm", { trim: false })
                    : "-"}
                </td>
                <td>
                  {item.total_cleaned_area_m2
                    ? item.total_cleaned_area_m2.toFixed(1)
                    : "-"}
                </td>
                <td>{item.total_kickouts - item.autoresumed_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  return <div className="column is-12">{content}</div>;
};

export default DeviceList;
