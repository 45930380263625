import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import { useHistory } from "react-router-dom";
import ErrorIcon from "../../../components/ErrorIcon";
import LoadingIcon from "../../../components/LoadingIcon";

const DeviceTransfer = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAutonomyLoaded, setIsAutonomyLoaded] = useState(false);
  const [autonomyKitDropdown, setAutonomyKitDropdown] = useState([]);
  const [autonomyKitDropdownError, setAutonomyKitDropdownError] = useState(
    null
  );
  const [autonomyKit, setAutonomyKit] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationDropdown, setOrganizaitonDropdown] = useState([]);
  const [organizationDropdownError, setOrganizationDropdownError] = useState(
    null
  );
  const [organizationError, setOrganizationError] = useState(null);
  const [isOrgLoaded, setIsOrgLoaded] = useState(false);
  const [autonomyError, setAutonomyError] = useState("");
  const [submitError, setSubmitError] = useState(null);
  const { get, post } = useContext(AuthClientContext);
  const history = useHistory();

  const buttonRedirect = () => {
    history.push(`/devices`);
  };

  useEffect(() => {
    get(`/admin/v1/organizations/`).then(
      (result) => {
        setOrganizaitonDropdown(result.data);
        setIsOrgLoaded(true);
      },
      (error) => {
        setOrganizationDropdownError(error);
        setIsOrgLoaded(true);
      }
    );
  }, [get]);

  useEffect(() => {
    get(`/v1/devices/`).then(
      (result) => {
        setAutonomyKitDropdown(result.data);
        setIsAutonomyLoaded(true);
      },
      (error) => {
        setAutonomyKitDropdownError(error);
        setIsAutonomyLoaded(true);
      }
    );
  }, [get]);

  useEffect(() => {
    setIsLoaded(isAutonomyLoaded && isOrgLoaded);
  }, [isAutonomyLoaded, isOrgLoaded]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAutonomyError("");
    setOrganizationError("");
    let isalphanumeric = /^[a-z0-9]+$/i.test(autonomyKit);
    if (!isalphanumeric) {
      setAutonomyError(
        "Autonomy Kit must be alphanumeric and at least 1 character long"
      );
      return;
    }
    if (!organization) {
      setOrganizationError("Please select a Organization");
      return;
    }
    post(`/v1/devices/register`, {
      autonomy_kit: autonomyKit,
      tenant: organization,
      include_historical_data: false,
    }).then(
      (_) => {
        buttonRedirect();
      },
      (error) => {
        setSubmitError(error);
      }
    );
  };

  let organizationContent = "";
  if (organizationDropdownError) {
    organizationContent = (
      <ErrorIcon text={organizationDropdownError.message} />
    );
  } else if (!isLoaded) {
    organizationContent = <LoadingIcon />;
  } else {
    organizationContent = (
      <>
        <div className="select">
          <select onChange={(e) => setOrganization(e.target.value)}>
            <option value="" defaultValue>
              Select
            </option>
            {organizationDropdown.map((x, i) => (
              <option key={i} value={x.primary_tenant}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }

  let autonomyDropdownContent = "";
  if (autonomyKitDropdownError) {
    autonomyDropdownContent = (
      <ErrorIcon text={autonomyKitDropdownError.message} />
    );
  } else if (!isLoaded) {
    autonomyDropdownContent = <LoadingIcon />;
  } else {
    autonomyDropdownContent = (
      <>
        <div className="select">
          <select onChange={(e) => setAutonomyKit(e.target.value)}>
            <option value="" defaultValue>
              Select
            </option>
            {autonomyKitDropdown.map((x, i) => (
              <option key={i} value={x.autonomy_kit}>
                {x.buddy_name}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }

  let submitContent = "";
  if (submitError) {
    submitContent = <ErrorIcon text={submitError.message} />;
  }
  return (
    <div>
      <h1 className="title">Transfer Machine</h1>
      <div className="columns">
        <div className="column is-half">
          <form
            className="box"
            onSubmit={(e) => handleSubmit(e)}
            data-testid="device-registration-form"
          >
            <div className="field">
              <label className="label">Autonomy Kit</label>
              <div className="control">{autonomyDropdownContent}</div>
              <p
                data-testid="error-autonomy"
                id="error-autonomy"
                className="help is-danger"
              >
                {autonomyError}
              </p>
            </div>
            <div className="field">
              <label className="label">Organization</label>
              <div className="control">{organizationContent}</div>
              <p
                data-testid="error-organization"
                id="error-organization"
                className="help is-danger"
              >
                {organizationError}
              </p>
            </div>
            <hr />
            <div className="field is-grouped">
              <div className="control">
                <button data-testid="button-submit" className="button is-link">
                  Submit
                </button>
              </div>
              <div className="control">
                <button
                  data-testid="button-cancel"
                  className="button is-link is-light"
                  onClick={() => buttonRedirect()}
                >
                  Cancel
                </button>
              </div>
            </div>
            {submitContent}
          </form>
        </div>
      </div>
    </div>
  );
};
export default DeviceTransfer;
