import "./style.css";
import React from "react";

const SortTableHeader = ({
  name = "",
  desc = null,
  field = "",
  sort = "",
  clickHandler = () => {},
}) => {
  let arrow;
  if (field === sort) {
    arrow = desc ? (
      <i className="fas fa-arrow-down" />
    ) : (
      <i className="fas fa-arrow-up" />
    );
  }
  return (
    <th onClick={clickHandler}>
      {name} {arrow}
    </th>
  );
};
export default SortTableHeader;
