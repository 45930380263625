import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import { ResponsiveBar } from "@nivo/bar";
import ErrorIcon from "../../../components/ErrorIcon";

const aggregateTime = (metrics) => {
  if (!metrics || metrics.length === 0) {
    return [];
  }
  const m = metrics
    .map((c) => {
      return {
        date: c.date,
        "Runtime (min)": (c.total_runtime / 60).toFixed(1),
      };
    })
    .reverse();
  return m;
};

const RuntimeChart = ({ id, startDate, endDate }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    let params = {};
    if (startDate) {
      params["start_date"] = startDate.format();
    }
    if (endDate) {
      params["end_date"] = endDate.format();
    }
    get(`/v1/metrics/scrubber/plans/${id}/daily`, { params: params }).then(
      (result) => {
        setData(aggregateTime(result.data));
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get, startDate, endDate]);

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else if (data.length === 0) {
    content = (
      <div data-testid="runtime-chart-no-data" className="RuntimeChart-no-data">
        There is no device activity to display.
      </div>
    );
  } else {
    content = (
      <ResponsiveBar
        data={data}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        keys={["Runtime (min)"]}
        indexBy="date"
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: "linear" }}
        colors={{ scheme: "category10" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 32,
          format: (e) => (data.length > 5 ? null : e),
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          format: (e) => Math.floor(e) === e && e,
          tickRotation: 0,
          legendPosition: "middle",
          legend: "Minutes",
          legendOffset: -40,
        }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        enableLabel={false}
      />
    );
  }
  return (
    <div className="RuntimeChart">
      <div className="RuntimeChart-heading">Daily Plan Runtime</div>
      <div className="RuntimeChart-body">{content}</div>
    </div>
  );
};

export default RuntimeChart;
