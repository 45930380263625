import "./style.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import Navbar from "../Navbar";

const OktaNavbar = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState(null);
  const logout = async () => {
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut();
  };
  useEffect(() => {
    if (
      window.Cypress &&
      window.Cypress.spec.name.substring(
        0,
        window.Cypress.spec.name.indexOf(".")
      ) !== "login"
    ) {
      let cypressAuthState = JSON.parse(localStorage.getItem("authState"));
      if (cypressAuthState != null) {
        let info = JSON.parse(localStorage.getItem("oktaCypress"));
        setUserInfo(info.user);
        authState.isAuthenticated = cypressAuthState.isAuthenticated;
        authState.accessToken = JSON.parse(cypressAuthState.accessToken);
        authState.idToken = JSON.parse(cypressAuthState.idToken);
        authState.isPending = cypressAuthState.isPending;
      }
      return;
    }
    if (!authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  return (
    <Navbar
      isPending={authState.isPending}
      isAuthenticated={authState.isAuthenticated}
      logoutClick={logout}
      homeClick={() => history.push("/")}
      userInfo={userInfo}
    />
  );
};

export default OktaNavbar;
