import "./style.css";
const ViewHeader = ({ id, name, edit }) => {
  return (
    <div className="ViewHeader">
      <span className="ViewHeader-title">
        <h1 className="title">{name}</h1>
      </span>
      <span
        className={"ViewHeader-id" + (edit ? " ViewHeader-id-move-right" : "")}
      >
        <h1 data-testid="view-header-id" className="subtitle has-text-right">
          {id}
          {edit && <i className="fas fa-edit edit" onClick={edit}></i>}
        </h1>
      </span>
    </div>
  );
};

export default ViewHeader;
