import ListDevices from "../../pages/Device/ListDevices";
import ListPlans from "../../pages/Plan/ListPlans";
import OktaNavbar from "../OktaNavbar";
import { Switch, Route, useHistory } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { oidc } from "../../config";
import SignIn from "../SignIn";
import { AuthClientContextProvider } from "../../services/Api/AuthClient";
import { RoleContextProvider } from "../../services/Api/Role";
import ViewPlan from "../../pages/Plan/ViewPlan";
import ViewDevice from "../../pages/Device/ViewDevice";
import RegisterDevice from "../../pages/Device/RegisterDevice";
import ViewPlayback from "../../pages/Playback/ViewPlayback";
import ViewTroubleshoot from "../../pages/Troubleshoot/ViewTroubleshoot";
import ListTroubleshoots from "../../pages/Troubleshoot/ListTroubleshoots";
import CreateUser from "../../pages/Settings/User/CreateUser";
import Settings from "../../pages/Settings/Landing";
import ListOrganizations from "../../pages/Settings/Organization/ListOrganizations";
import CreateOrganization from "../../pages/Settings/Organization/CreateOrganization";
import ListUsers from "../../pages/Settings/User/ListUsers";
import EditUser from "../../pages/Settings/User/EditUser";
import TransferDevice from "../../pages/Device/TransferDevice";
import Feedback from "../../pages/Feedback";
import EditOrganization from "../../pages/Settings/Organization/EditOrganization";
import Report from "../../pages/Report";
import DevicesReport from "../../pages/Report/DevicesReport";
import RunReport from "../../pages/Report/RunsReport";
import KickoutReport from "../../pages/Report/KickoutReport";
import PlanDevicesReport from "../../pages/Report/PlanDevicesReport";
import Home from "../../pages/Home";

const AppRouter = () => {
  const oktaAuth = new OktaAuth(oidc);
  const history = useHistory();
  const customAuthHandler = () => {
    history.push("/login");
  };
  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler}>
      <OktaNavbar />
      <section className="section">
        <div className="container">
          <AuthClientContextProvider>
            <RoleContextProvider>
              <Switch>
                <SecureRoute
                  exact={true}
                  path="/devices/register"
                  component={RegisterDevice}
                />
                <SecureRoute
                  exact={true}
                  path="/devices/transfer"
                  component={TransferDevice}
                />
                <SecureRoute path="/devices/:id" component={ViewDevice} />
                <SecureRoute
                  exact={true}
                  path="/devices"
                  component={ListDevices}
                />
                <SecureRoute path="/plans/:id" component={ViewPlan} />
                <SecureRoute path="/playbacks/:id" component={ViewPlayback} />
                <SecureRoute
                  exact={true}
                  path="/troubleshoot"
                  component={ListTroubleshoots}
                />
                <SecureRoute
                  path="/troubleshoot/:id"
                  component={ViewTroubleshoot}
                />
                <SecureRoute exact={true} path="/plans" component={ListPlans} />
                <SecureRoute exact={true} path="/users" component={ListUsers} />
                <SecureRoute
                  exact={true}
                  path="/users/create"
                  component={CreateUser}
                />
                <SecureRoute path="/users/:id" component={EditUser} />
                <SecureRoute
                  exact={true}
                  path="/settings"
                  component={Settings}
                />

                <SecureRoute
                  exact={true}
                  path="/organizations"
                  component={ListOrganizations}
                />
                <SecureRoute
                  exact={true}
                  path="/organizations/create"
                  component={CreateOrganization}
                />
                <SecureRoute
                  path="/organizations/:id/edit"
                  component={EditOrganization}
                />
                <SecureRoute
                  path="/reports/devices"
                  component={DevicesReport}
                />
                <SecureRoute path="/reports/runs" component={RunReport} />
                <SecureRoute
                  path="/reports/kickouts"
                  component={KickoutReport}
                />
                <SecureRoute
                  path="/reports/plan-devices"
                  component={PlanDevicesReport}
                />
                <SecureRoute path="/reports" component={Report} />
                <SecureRoute path="/feedback" component={Feedback} />
                <SecureRoute exact={true} path="/" component={Home} />
              </Switch>
            </RoleContextProvider>
          </AuthClientContextProvider>
          <Route path="/login" render={() => <SignIn />} />
          <Route path="/login/callback" component={LoginCallback} />
        </div>
      </section>
    </Security>
  );
};

export default AppRouter;
