import React from "react";
import UserList from "../../../../domain/User/UserList";
import { useHistory } from "react-router-dom";

const ListUsers = () => {
  const history = useHistory();

  const rowClick = (id) => {
    history.push(`/users/${id}`);
  };

  return (
    <div>
      <a href="/users/create" className="button is-link is-pulled-right">
        <span className="icon">
          <i className="fas fa-plus"></i>
        </span>
        <span>Add</span>
      </a>
      <h1 className="title">Users</h1>
      <div className="columns is-centered">
        <div className="box column is-12">
          <UserList rowClick={rowClick} />
        </div>
      </div>
    </div>
  );
};

export default ListUsers;
