import "./style.css";
import React, { useState, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";

const UpdateNameModal = ({
  id,
  name = "",
  siteName = "",
  opened = false,
  close,
}) => {
  const { post } = useContext(AuthClientContext);

  const [updatedName, setUpdatedName] = useState(name);
  const [updatedSiteName, setUpdatedSiteName] = useState(siteName);
  const [error, setError] = useState(null);

  const closeModal = () => {
    close();
    setUpdatedName(name);
    setError(null);
  };

  const submit = () => {
    post(`/v1/plans/${id}/metadata`, {
      name: updatedName,
      site_name: updatedSiteName,
    }).then(
      (_) => {
        close();
      },
      (error) => {
        setError(error);
      }
    );
  };

  return (
    <div className={"modal" + (opened ? " is-active" : "")}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Update Plan</p>
          <button
            className="delete"
            aria-label="close"
            onClick={closeModal}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label id="plan-name-label" className="label">
              Plan Name
            </label>
            <div className="control">
              <input
                aria-labelledby="plan-name-label"
                className="input"
                type="text"
                onChange={(e) => setUpdatedName(e.target.value)}
                value={updatedName}
              />
            </div>
          </div>
          <div className="field">
            <label id="site-name-label" className="label">
              Site Name
            </label>
            <div className="control">
              <input
                aria-labelledby="plan-name-label"
                className="input"
                type="text"
                onChange={(e) => setUpdatedSiteName(e.target.value)}
                value={updatedSiteName}
              />
            </div>
          </div>
          {error && (
            <div className="notification is-danger">{error.message}</div>
          )}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={submit}>
            Save changes
          </button>
          <button className="button" onClick={closeModal}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default UpdateNameModal;
