import "./style.css";
import React, { useState, useEffect, useContext } from "react";

import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";

const aggregateKickouts = (metrics) => {
  return metrics
    .reduce((acc, curr) => {
      acc = acc.concat(curr.kickout_types);
      return acc;
    }, [])
    .reduce((acc, curr) => {
      let ko = acc.find((a) => a.id === curr.id);
      if (ko) {
        ko.count += curr.count;
      } else {
        ko = { id: curr.id, name: curr.name, count: curr.count };
        acc.push(ko);
      }
      return acc;
    }, []);
};

const KickoutChart = ({ id, rowClick, startDate, endDate }) => {
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    let params = {};
    params[`filterBy[autonomy_kit]`] = id;
    if (startDate) {
      params["start_date"] = startDate.format();
    }
    if (endDate) {
      params["end_date"] = endDate.format();
    }
    get(`/v1/metrics/playbacks`, { params: params }).then(
      (result) => {
        setList(aggregateKickouts(result.data));
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get, startDate, endDate]);

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <table data-testid="KickoutChart-body" className="table is-hoverable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, i) => (
            <tr key={item.id} onClick={() => rowClick(item.id)}>
              <td className="link-text">{item.name}</td>
              <td>{item.id}</td>
              <td>{item.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <div data-testid="kickout-chart" className="KickoutChart">
      <div className="table-heading">Kickout Types</div>
      <div className="KickoutChart-body ">{content}</div>
    </div>
  );
};

export default KickoutChart;
