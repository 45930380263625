import "./style.css";
import React, { useState, useEffect } from "react";

const ModalMap = ({
  fixedViewBoxValue,
  maps,
  matrix,
  segmentFill,
  isModalOpen,
  closeModal,
  showPath,
  togglePath,
  toggleKickouts,
  kickouts,
  isFillIn = false,
}) => {
  const [svg, setSVG] = useState(null);
  const [svgSize, setSVGSize] = useState({});
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [originViewBox, setOriginViewBox] = useState({});
  const [pointerOrigin, setPointerOrigin] = useState({});
  const [modalSVGHeight, setModalSVGHeight] = useState("0");
  const [viewBoxValue, setViewBoxValue] = useState("0 0 0 0");

  useEffect(() => {
    let svg = document.querySelector(".modal").querySelector("svg");
    svg.addEventListener("wheel", (e) => e.preventDefault(), {
      passive: false,
    });
    setSVG(svg);
  }, []);

  useEffect(() => {
    if (isModalOpen && svg) {
      setViewBoxValue(fixedViewBoxValue);
      let modal = document.querySelector(".modal-content");
      let body = document
        .querySelector(".modal")
        .querySelector(".PlanMap-body");
      let padding = window
        .getComputedStyle(body, null)
        .getPropertyValue("padding-top");
      let paddingHeight = parseFloat(padding.substring(0, padding.length - 2));
      let buttonHeight = document
        .querySelector(".map-button")
        .getBoundingClientRect().height;

      let height =
        modal.getBoundingClientRect().height - paddingHeight - buttonHeight;
      setModalSVGHeight(`${height}`);
      setSVGSize({ w: svg.clientWidth, h: height });
    }
  }, [fixedViewBoxValue, svg, isModalOpen]);

  const onMouseWheel = (e) => {
    e = e.nativeEvent;
    let viewBox = {
      x: parseFloat(svg.getAttribute("viewBox").split(" ")[0]),
      y: parseFloat(svg.getAttribute("viewBox").split(" ")[1]),
      width: parseFloat(svg.getAttribute("viewBox").split(" ")[2]),
      height: parseFloat(svg.getAttribute("viewBox").split(" ")[3]),
    };
    let mx = e.offsetX; //mouse x
    let my = e.offsetY;
    let dw = viewBox.width * Math.sign(e.deltaY) * 0.05;
    let dh = viewBox.height * Math.sign(e.deltaY) * 0.05;
    let dx = (dw * mx) / svgSize.w;
    let dy = (dh * my) / svgSize.h;
    setViewBoxValue(
      `${viewBox.x + dx} ${viewBox.y + dy} ${viewBox.width - dw} ${
        viewBox.height - dh
      }`
    );
  };

  const onPointerDown = (event) => {
    setIsPointerDown(true);
    event.target.style.cursor = "grabbing";
    setOriginViewBox({
      x: parseFloat(svg.getAttribute("viewBox").split(" ")[0]),
      y: parseFloat(svg.getAttribute("viewBox").split(" ")[1]),
      width: parseFloat(svg.getAttribute("viewBox").split(" ")[2]),
      height: parseFloat(svg.getAttribute("viewBox").split(" ")[3]),
    });
    setPointerOrigin({ x: event.clientX, y: event.clientY });
  };

  const onPointerUp = (event) => {
    setIsPointerDown(false);
    event.target.style.cursor = "";
  };

  const onPointerMove = (event) => {
    if (!isPointerDown) {
      return;
    }
    let scale = svgSize.w / originViewBox.width;
    let changeX = (event.clientX - pointerOrigin.x) / scale;
    let changeY = (event.clientY - pointerOrigin.y) / scale;
    setViewBoxValue(
      `${originViewBox.x - changeX} ${originViewBox.y - changeY} ${
        originViewBox.width
      } ${originViewBox.height}`
    );
  };

  let modalContent;
  const pathClassNames = isFillIn
    ? "training-path training-path-filled"
    : "training-path";
  modalContent = (
    <svg
      data-testid="modal-plan-image"
      width="100%"
      height={modalSVGHeight}
      viewBox={viewBoxValue}
      onPointerDown={(e) => onPointerDown(e)}
      onPointerUp={(e) => onPointerUp(e)}
      onPointerMove={(e) => onPointerMove(e)}
      onMouseLeave={(e) => onPointerUp(e)}
      onWheel={(e) => onMouseWheel(e)}
    >
      <image href={maps.map_image_url} height={maps.width} />
      <g transform={matrix}>
        <path d={segmentFill} className={pathClassNames} />
        {kickouts}
      </g>
    </svg>
  );

  let icon = showPath ? "fa-tint" : "fa-draw-polygon";
  var buttonText = showPath ? "Show Cleaned" : "Show Recorded Plan";

  let pathCSS = showPath
    ? `.PlanMap-body .training-path { stroke: black; stroke-width: 0.1; stroke-linejoin: round; stroke-miterlimit:4; stroke-opacity: 0.95; fill: none }`
    : "";

  return (
    <div className="modal">
      <div
        data-testid="modal-background"
        className="modal-background"
        onClick={() => closeModal()}
      ></div>
      <div className="modal-content">
        <style>{pathCSS}</style>
        <div className="PlanMap-body">
          <div
            data-testid="modal-toggle-path"
            className="button is-light is-info is-pulled-right map-button"
            onClick={() => togglePath(!showPath)}
          >
            <i className={["fas " + icon]} />
            {buttonText}
          </div>
          <div
            data-testid="panel-toggle-kickout"
            className="button is-light is-danger is-pulled-right kickout-button"
            onClick={toggleKickouts}
          >
            <i className="fas fa-stop-circle" />
            Toggle Kickouts
          </div>
          <div>{modalContent}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalMap;
