import "./style.css";
import Moment from "react-moment";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";

import { useHistory } from "react-router-dom";
import { RoleContext } from "../../../services/Api/Role";
import ErrorIcon from "../../../components/ErrorIcon";

const OrganizationList = () => {
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);
  const { role } = useContext(RoleContext);

  const history = useHistory();
  const isSuperAdmin = role === "superadmin";

  let rowClick = () => {};
  if (isSuperAdmin) {
    rowClick = (id) => {
      history.push(`/organizations/${id}/edit`);
    };
  }

  useEffect(() => {
    get("/admin/v1/organizations/").then(
      (result) => {
        setList(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [get]);

  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <table
        data-testid="organization-table"
        className="table OrganizationList is-hoverable"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Alias</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => (
            <tr key={item.id} onClick={() => rowClick(item.id)}>
              <td>{item.name}</td>
              <td>{item.alias}</td>
              <td>
                <Moment format="YYYY-MM-DD" utc local>
                  {item.created_at}
                </Moment>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return <div className="column is-12">{content}</div>;
};

export default OrganizationList;
