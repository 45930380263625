import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import ModalMap from "./ModalMap";
import PanelMap from "./PanelMap";
import { useHistory } from "react-router-dom";

const PlanMap = ({ id, startDate, endDate, isFillIn = false }) => {
  const [maps, setMaps] = useState(null);
  const [showPath, togglePath] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [kickouts, setKickouts] = useState([]);
  const [kickoutInfo, setKickoutInfo] = useState(null);
  const [showKickouts, toggleShowKickouts] = useState(false);
  const [fixedViewBoxValue, setFixedViewBoxValue] = useState("0 0 0 0");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { get } = useContext(AuthClientContext);

  const history = useHistory();

  useEffect(() => {
    get(`/v1/plans/${id}/maps`).then(
      (result) => {
        setMaps(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get]);

  useEffect(() => {
    if (!showKickouts) {
      setKickouts([]);
      return;
    }
    let params = {};
    if (startDate) {
      params["start_date"] = startDate.format();
    }
    if (endDate) {
      params["end_date"] = endDate.format();
    }
    get(`/v1/plans/${id}/kickouts/`, { params: params }).then(
      (result) => {
        const kickouts = result.data.reduce((acc, m) => {
          const id = `kickout-segment-${m.id}`;
          const err_msg = m.error_code ? m.error_message : "Completed";
          const err_code = m.error_code;
          const completedClass = err_msg === "Completed" ? "completed" : "";
          acc.push(
            <circle
              cx={m.kickout_coordinate.x}
              cy={m.kickout_coordinate.y}
              r=".3"
              className={`kickout ${completedClass}`}
              id={id}
              key={id}
              onMouseOver={(e) => {
                let currentTarget = document
                  .getElementsByClassName("container")[0]
                  .getBoundingClientRect();
                setKickoutInfo({
                  type: err_msg,
                  code: err_code,
                  time: m.end_time,
                  x: e.pageX - window.scrollX - currentTarget.left,
                  y: e.pageY - window.scrollY - currentTarget.top,
                  opacity: 1,
                });
              }}
              onClick={() => {
                history.push(`/playbacks/${m.playback_id}`);
              }}
              onMouseOut={() => {
                setKickoutInfo({
                  opacity: 0,
                });
              }}
            />
          );
          return acc;
        }, []);
        setKickouts(kickouts);
      },
      (error) => {
        setError(error);
      }
    );
  }, [id, showKickouts, history, startDate, endDate, get]);

  useEffect(() => {
    if (isLoaded && maps) {
      setFixedViewBoxValue(`0 0 ${maps.height}, ${maps.width}`);
    }
  }, [isLoaded, maps]);

  const openModal = () => {
    document.querySelector(".modal").classList.add("is-active");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    document.querySelector(".modal").classList.remove("is-active");
    setIsModalOpen(false);
  };

  let content;
  let tooltip;
  if (error) {
    content = (
      <div className="PlanMap-panel">
        <div className="PlanMap-heading">Map</div>
        <div className="PlanMap-body">
          <ErrorIcon text="Unable to load map at this time" />
        </div>
      </div>
    );
  } else if (!isLoaded) {
    content = (
      <div className="PlanMap-panel">
        <div className="PlanMap-heading">Map</div>
        <div className="PlanMap-body">
          <LoadingIcon />
        </div>
      </div>
    );
  } else {
    tooltip = (
      <>
        {kickoutInfo && (
          <div
            data-testid="tooltip"
            className="tooltip-donut"
            style={{
              left: kickoutInfo.x + "px",
              top: kickoutInfo.y + "px",
              opacity: kickoutInfo.opacity,
              transition: "opacity .25s",
            }}
          >
            {kickoutInfo.type ? kickoutInfo.type : "Unknown"}
            {kickoutInfo.code && (
              <>
                <br /> Code: {kickoutInfo.code}
              </>
            )}
            <br />{" "}
            {new Date(kickoutInfo.time)
              .toString()
              .substring(
                0,
                new Date(kickoutInfo.time).toString().lastIndexOf("(") - 1
              )}
          </div>
        )}
      </>
    );
    const coords = maps.training_coordinates;
    const segmentFill = coords.reduce((b, c) => {
      return b + `${c.x}, ${c.y}, `;
    }, "M ");
    const matrix = `matrix(${maps.matrix[0]} ${maps.matrix[1]} ${maps.matrix[2]} ${maps.matrix[3]} ${maps.matrix[4]} ${maps.matrix[5]})`;

    content = (
      <>
        <PanelMap
          fixedViewBoxValue={fixedViewBoxValue}
          maps={maps}
          matrix={matrix}
          segmentFill={segmentFill}
          openModal={openModal}
          showPath={showPath}
          toggleKickouts={() => toggleShowKickouts(!showKickouts)}
          togglePath={togglePath}
          tooltip={tooltip}
          isFillIn={isFillIn}
          kickouts={kickouts.map((x) => x)}
        ></PanelMap>
        <ModalMap
          fixedViewBoxValue={fixedViewBoxValue}
          maps={maps}
          matrix={matrix}
          segmentFill={segmentFill}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          showPath={showPath}
          toggleKickouts={() => toggleShowKickouts(!showKickouts)}
          togglePath={togglePath}
          tooltip={tooltip}
          isFillIn={isFillIn}
          kickouts={kickouts.map((x) => x)}
        ></ModalMap>
      </>
    );
  }

  return <>{content}</>;
};

export default PlanMap;
