import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import ErrorIcon from "../../../components/ErrorIcon";
import LoadingIcon from "../../../components/LoadingIcon";
import { useHistory } from "react-router-dom";

const UserEdit = ({ role, id }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState(null);
  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [rolesError, setRolesError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [submitError, setSubmitError] = useState(null);
  const { get, post } = useContext(AuthClientContext);
  const history = useHistory();

  useEffect(() => {
    if (role === "admin") {
      setRoles(["user"]);
    }
  }, [role]);

  useEffect(() => {
    get(`/admin/v1/users/${id}`).then(
      (result) => {
        setUser(result.data);
        setFirstName(result.data.first_name);
        setLastName(result.data.last_name);
        setEmail(result.data.username);
        setRoles(result.data.roles);
        setOrganization(result.data.organization);

        setIsLoaded(true);
      },
      (error) => {
        setIsLoaded(true);
      }
    );
  }, [get, id]);

  const handleRoleInput = (e) => {
    let selected = e.target.selectedOptions;
    let values = [];
    for (let i = 0; i < selected.length; i++) {
      values.push(selected[i].value);
    }
    setRoles(values);
  };

  const buttonRedirect = () => {
    history.push(`/`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setRolesError("");
    setOrganizationError("");
    if (!firstName) {
      setFirstNameError("Please input a first name");
    } else if (!lastName) {
      setLastNameError("Please input a last name");
    } else if (!email) {
      setEmailError("Please input an email address");
    } else if (roles.length === 0) {
      setRolesError("Please choose one or more roles");
    } else if (role === "superadmin" && !organization) {
      setOrganizationError("Please choose an organization");
    }
    if (
      !firstName ||
      !lastName ||
      !email ||
      roles.length === 0 ||
      organizationError !== ""
    ) {
      return;
    }
    post(`admin/v1/users`, {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      roles: roles,
      organization: organization,
    }).then(
      (result) => {
        buttonRedirect();
      },
      (error) => {
        setSubmitError(error);
      }
    );
  };
  let content;
  let submitErrorContent = "";
  if (submitError) {
    submitErrorContent = <ErrorIcon text={submitError.message} />;
  }
  if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <>
        <h1 className="title">{user.first_name + " " + user.last_name}</h1>
        <div className="columns">
          <div className="column is-half">
            <form
              className="box"
              onSubmit={(e) => handleSubmit(e)}
              data-testid="user-edit-form"
            >
              <div className="field">
                <label id="first-name-label" className="label">
                  First Name
                </label>
                <div className="control">
                  <input
                    aria-labelledby="first-name-label"
                    className="input"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <p
                  data-testid="error-first-name"
                  id="error-first-name"
                  className="help is-danger"
                >
                  {firstNameError}
                </p>
              </div>
              <div className="field">
                <label id="last-name-label" className="label">
                  Last Name
                </label>
                <div className="control">
                  <input
                    aria-labelledby="last-name-label"
                    className="input"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <p
                  data-testid="error-last-name"
                  id="error-last-name"
                  className="help is-danger"
                >
                  {lastNameError}
                </p>
              </div>
              <div className="field">
                <label id="email-label" className="label">
                  Email
                </label>
                <div className="control has-icons-left">
                  <input
                    aria-labelledby="email-label"
                    className="input"
                    type="text"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    readOnly
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                </div>
                <p
                  data-testid="error-email"
                  id="error-email"
                  className="help is-danger"
                >
                  {emailError}
                </p>
              </div>
              {role === "superadmin" && (
                <div className="field">
                  <label id="roles-label" className="label">
                    Roles
                  </label>
                  <div className="control" aria-labelledby="roles-label">
                    <div className="select">
                      <select
                        value={roles}
                        onChange={(e) => handleRoleInput(e)}
                      >
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <option value="superadmin">Super Admin</option>
                      </select>
                    </div>
                  </div>
                  <p
                    data-testid="error-roles"
                    id="error-roles"
                    className="help is-danger"
                  >
                    {rolesError}
                  </p>
                </div>
              )}

              <div className="field is-grouped">
                <div className="control">
                  <button
                    data-testid="button-submit"
                    className="button is-link"
                  >
                    Submit
                  </button>
                </div>
              </div>
              {submitErrorContent}
            </form>
          </div>
        </div>
      </>
    );
  }

  return <div>{content}</div>;
};

export default UserEdit;
