import "./style.css";

import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";

import FileSaver from "file-saver";

import { Link } from "react-router-dom";
import { ResponsiveLine } from "@nivo/line";

import Filter from "../../../components/Filter";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import SortTableHeader from "../../../components/SortTableHeader";
import moment from "moment";

const PlanDevicesReport = () => {
  const [playbacks, setPlaybacks] = useState([]);
  const [devices, setDevices] = useState([]);
  const [inactiveDevices, setInactiveDevices] = useState([]);
  const [aggregateMetrics, setAggregateMetrics] = useState(null);
  const [cleaningTimeData, setCleaningTimeData] = useState([]);
  const [kickoutData, setKickoutData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isChartsLoaded, setIsChartsLoaded] = useState(false);
  const [isOverviewLoaded, setIsOverviewLoaded] = useState(false);
  const [isDeviceLoaded, setIsDeviceLoaded] = useState(false);
  const [processingCSV, setProcessingCSV] = useState(false);
  const { get } = useContext(AuthClientContext);
  const [sort, setSort] = useState("device_alias");
  const [desc, setDesc] = useState(false);

  const [params, setParams] = useState(null);

  const clickSort = (val) => {
    if (val === sort) {
      setDesc(!desc);
    } else {
      setSort(val);
      setDesc(true);
    }
  };

  useEffect(() => {
    get(`/v1/devices/`).then(
      (result) => {
        setDevices(result.data);
        setIsDeviceLoaded(true);
      },
      (error) => {
        setError(error);
        setIsDeviceLoaded(true);
      }
    );
  }, [get]);

  useEffect(() => {
    if (aggregateMetrics) {
      setInactiveDevices(devices.length - aggregateMetrics.machineCount);
    }
  }, [devices, aggregateMetrics]);

  useEffect(() => {
    if (params) {
      get(`/v1/metrics/scrubber/report/plan-devices`, {
        params: {
          ...params,
          size: 100,
          sortBy: `${sort}:${desc ? "desc" : "asc"}`,
        },
      }).then(
        (result) => {
          setPlaybacks(result.data);
          setIsLoaded(true);
        },
        (error) => {
          setError(error);
          setIsLoaded(true);
        }
      );
    }
  }, [get, params, sort, desc]);

  useEffect(() => {
    if (params) {
      get(`/v1/metrics/dashboard/runs/overview`, { params: params }).then(
        (result) => {
          setAggregateMetrics({
            totalRuntime: result.data.total_runtime,
            totalKickouts:
              result.data.total_kickouts - result.data.total_autoresumed,
            machineCount: result.data.total_devices,
            planCount: result.data.total_runs,
            averageDailyAutonomousRuntime:
              result.data.total_runtime !== 0
                ? (
                    result.data.total_runtime /
                    (result.data.total_devices *
                      result.data.max_days_of_activation)
                  ).toFixed(1)
                : 0,
            averageDailyAreaCleaned:
              result.data.total_area_cleaned !== 0
                ? (
                    result.data.total_area_cleaned /
                    (result.data.total_devices *
                      result.data.max_days_of_activation)
                  ).toFixed(1)
                : 0,
            averageCleanedArea: result.data.total_area_cleaned
              ? (
                  result.data.total_area_cleaned /
                  (result.data.total_runtime / 3600)
                ).toFixed(1)
              : 0,
            averageRuntime:
              result.data.total_runtime !== 0
                ? (
                    result.data.total_runtime /
                    3600 /
                    result.data.total_runs
                  ).toFixed(1)
                : 0,
          });

          setIsOverviewLoaded(true);
        },
        (error) => {
          setError(error);
          setIsOverviewLoaded(true);
        }
      );
    }
  }, [get, params]);

  useEffect(() => {
    if (params) {
      get(`/v1/metrics/dashboard/daily_aggregate`, { params: params }).then(
        (result) => {
          setCleaningTimeData([
            {
              id: "runtime",
              data: result.data.reverse().map((x) => {
                return { x: x.date, y: x.total_runtime / 3600 };
              }),
            },
          ]);
          setKickoutData([
            {
              id: "kickouts",
              data: result.data.map((x) => {
                return { x: x.date, y: x.total_kickouts - x.total_autoresumed };
              }),
            },
          ]);
          setIsChartsLoaded(true);
        },
        (error) => {
          setError(error);
          setIsChartsLoaded(true);
        }
      );
    }
  }, [get, params]);

  const getCSV = () => {
    setProcessingCSV(true);
    get(`/v1/metrics/scrubber/report/plan-devices/csv`, {
      params: { ...params },
      responseType: "blob",
    }).then(
      (result) => {
        setProcessingCSV(false);
        const name = `plan-device-report-${moment().format(
          "YYYY-MM-DD-hh:mm"
        )}.csv`;
        FileSaver.saveAs(result.data, name);
      },
      (error) => {
        setProcessingCSV(false);
        console.log("error occurred", error);
      }
    );
  };
  let statsHeader;
  let playbackList;
  let runtimeGraph;
  let kickoutGraph;
  if (error) {
    statsHeader = <ErrorIcon text={error.message} />;
    playbackList = <ErrorIcon text={error.message} />;
    runtimeGraph = <ErrorIcon text={error.message} />;
    kickoutGraph = <ErrorIcon text={error.message} />;
  } else if (
    !params ||
    !isLoaded ||
    !isDeviceLoaded ||
    !isChartsLoaded ||
    !isOverviewLoaded
  ) {
    statsHeader = <LoadingIcon />;
    playbackList = <LoadingIcon />;
    runtimeGraph = <LoadingIcon />;
    kickoutGraph = <LoadingIcon />;
  } else {
    statsHeader = (
      <>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">
              Avg Daily Autonomous Runtime <br /> Per Machine
            </p>
            <p data-testid="total-runtime" className="title">
              {aggregateMetrics && isLoaded
                ? moment
                    .duration(
                      aggregateMetrics.averageDailyAutonomousRuntime,
                      "seconds"
                    )
                    .format("hh:mm", { trim: false })
                : 0}
            </p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">
              Avg Daily Area Cleaned (M&sup2;) <br /> Per Machine
            </p>
            <p data-testid="total-runtime" className="title">
              {aggregateMetrics && isLoaded
                ? aggregateMetrics.averageDailyAreaCleaned
                : "-"}
            </p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Avg Productivity (M&sup2;/h)</p>
            <p className="title">{aggregateMetrics.averageCleanedArea}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Active Machines</p>
            <p className="title">{aggregateMetrics.machineCount}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Inactive Machines</p>
            <p className="title">{inactiveDevices}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Plans Executed</p>
            <p className="title">{aggregateMetrics.planCount}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Total Kickouts</p>
            <p className="title">{aggregateMetrics.totalKickouts}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Kickouts/Hour</p>
            <p className="title">
              {(
                aggregateMetrics.totalKickouts /
                (aggregateMetrics.totalRuntime / 3600)
              ).toFixed(1)}
            </p>
          </div>
        </div>
      </>
    );
    playbackList = (
      <table className="table is-hoverable">
        <thead>
          <tr>
            <SortTableHeader
              name="Machine"
              field="device_alias"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("device_alias")}
            />

            <SortTableHeader
              name="Plan"
              field="plan_name"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("plan_name")}
            />

            <SortTableHeader
              name="Organization"
              field="tenant_name"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("tenant_name")}
            />

            <SortTableHeader
              name="Serial Number"
              field="serial_number"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("serial_number")}
            />

            <SortTableHeader
              name="Runtime"
              field="total_runtime"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("total_runtime")}
            />

            <SortTableHeader
              name="Avg Daily Runtime"
              field="avg_daily_runtime"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("avg_daily_runtime")}
            />
            <th>Avg Productivity (M&sup2;/h)</th>

            <SortTableHeader
              name="Area Cleaned (M&sup2;)"
              field="area_cleaned"
              sort={sort}
              desc={desc}
              calcField={true}
              clickHandler={() => clickSort("area_cleaned")}
            />

            <SortTableHeader
              name="Runs"
              field="total_runs"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("total_runs")}
            />

            <SortTableHeader
              name="Kickouts"
              field="total_kickouts"
              sort={sort}
              desc={desc}
              clickHandler={() => clickSort("total_kickouts")}
            />
          </tr>
        </thead>
        <tbody>
          {playbacks.map((item, i) => (
            <tr key={i}>
              <td>
                <Link to={`/devices/${item.device}`}>
                  {item.device_alias !== "" ? item.device_alias : item.device}
                </Link>
              </td>
              <td>{item.plan_name}</td>
              <td>{item.tenant_name}</td>
              <td>
                {item.device_serial_number ? item.device_serial_number : "-"}
              </td>
              <td>
                {moment
                  .duration(item.elapsed_time, "seconds")
                  .format("hh:mm", { trim: false })}
              </td>
              <td>
                {item.avg_daily_runtime
                  ? moment
                      .duration(item.avg_daily_runtime, "seconds")
                      .format("hh:mm", { trim: false })
                  : "-"}
              </td>
              <td>
                {(
                  item.total_cleaned_area_m2 /
                  (item.avg_daily_runtime / 3600)
                ).toFixed(1)}
              </td>
              <td>{item.total_cleaned_area_m2.toFixed(1)}</td>
              <td>{item.total_runs}</td>
              <td>{item.total_kickouts - item.total_autoresumed}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
    runtimeGraph = (
      <ResponsiveLine
        data={cleaningTimeData}
        margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
        indexBy="Date"
        padding={0.3}
        valueScale={{ type: "linear" }}
        colors={{ scheme: "category10" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          format: (e) => {
            return cleaningTimeData[0].data.length > 7 ? null : e;
          },
          legendOffset: 32,
          legend: "Date",
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          format: (e) => Math.floor(e) === e && e,
          tickRotation: 0,
          legendPosition: "middle",
          legend: "Runtime (minutes)",
          legendOffset: -40,
        }}
        useMesh={true}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        enableLabel={false}
      />
    );
    kickoutGraph = (
      <ResponsiveLine
        data={kickoutData}
        margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
        indexBy="Date"
        padding={0.3}
        valueScale={{ type: "linear" }}
        colors={{ scheme: "category10" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          format: (e) => (kickoutData[0].data.length > 7 ? null : e),
          legendOffset: 32,
          legend: "Date",
        }}
        useMesh={true}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          format: (e) => Math.floor(e) === e && e,
          tickRotation: 0,
          legendPosition: "middle",
          legend: "Kickouts",
          legendOffset: -40,
        }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        enableLabel={false}
      />
    );
  }
  return (
    <div data-testid="admin-dashboard">
      <h1 key="dashboard-title" className="title">
        Accumulated Plan Machine Reports
      </h1>

      <nav data-testid="fleet-stats-header" className="level box" role="banner">
        {statsHeader}
      </nav>
      <Filter handler={setParams} />
      <div className="ReportPlaybackRunsHeader">
        <span className="RunsTitle"></span>
        <span className="CSVExport has-text-right">
          {!processingCSV ? (
            <div className="button is-success" onClick={() => getCSV()}>
              <i className="fas fa-file-csv" />
              Download
            </div>
          ) : (
            <div disabled={true} className="button is-success">
              <i className="fas fa-spinner fa-spin" />
              Download
            </div>
          )}
        </span>
      </div>
      <div className="box ReportPlaybackTable">{playbackList}</div>
      <div className="mb-5">
        <h2 className="subtitle is-5">Trends</h2>
      </div>
      <div className="ReportGraph">
        <div className="ReportGraph-heading">Runtime</div>
        <div
          className="ReportGraph-body"
          data-testid="weekly-device-kickout-graph"
        >
          {runtimeGraph}
        </div>
      </div>
      <div className="ReportGraph">
        <div className="ReportGraph-heading">Kickout</div>
        <div
          className="ReportGraph-body"
          data-testid="weekly-device-kickout-graph"
        >
          {kickoutGraph}
        </div>
      </div>
    </div>
  );
};

export default PlanDevicesReport;
