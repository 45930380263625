import "./style.css";
import { React, useState } from "react";
import lunr from "lunr";

const createIdx = (values) => {
  return lunr(function () {
    this.ref("id");
    this.field("name");
    this.field("serial_number");

    values.forEach((doc) => {
      this.add(doc);
    }, this);
  });
};

const MultiselectDropdownWithSearch = ({
  title,
  items = [],
  selected = [],
  handleSelect = () => {},
  handleSelectAll = () => {},
  selectAll = false,
}) => {
  const [active, setActive] = useState(false);
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [filteredItems, setFilterItems] = useState(items);

  const idx = createIdx(items);

  const search = (val) => {
    const res = idx.search(`${val}*`);
    const filtered = items.filter((v) => res.some((r) => r.ref === v.id));
    setFilterItems(filtered);
  };

  const selectAllHandler = () => {
    !selectAllSelected
      ? handleSelectAll(filteredItems.map((m) => m.id))
      : handleSelectAll([]);
    setSelectAllSelected(!selectAllSelected);
  };
  // Solution: https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    // blur happens before click, preventing any click events in children from firing due to rerender from state change
    // so wait a tick for child component events to fire before changing open state and causing rerender
    window.setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setActive(false);
      }
    }, 100);
  };
  return (
    <div
      className={`MultiselectDropdown dropdown ${active ? "is-active" : ""}`}
      onBlur={handleBlur}
    >
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => setActive(!active)}
        >
          <span>{title}</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <div className="dropdown-item">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Device"
              onChange={(evt) => search(evt.target.value)}
            />
          </div>
          {selectAll ? (
            <>
              <hr />
              <div className="dropdown-item">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    onChange={selectAllHandler}
                    checked={selectAllSelected}
                  />
                  Select All
                </label>
              </div>{" "}
            </>
          ) : undefined}
          <hr />
          {filteredItems.map((item, i) => (
            <div key={i} className="dropdown-item">
              <label className="checkbox">
                <input
                  type="checkbox"
                  onChange={() => handleSelect(item.id)}
                  checked={selected.some((v) => v === item.id)}
                />
                <strong className="title is-6">{item.name}</strong>{" "}
                {item.serial_number}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default MultiselectDropdownWithSearch;
