import React from "react";

const PanelMap = ({
  fixedViewBoxValue,
  maps,
  matrix,
  segmentFill,
  openModal,
  showPath,
  togglePath,
  toggleKickouts,
  tooltip,
  kickouts,
  isFillIn = false,
}) => {
  let icon = showPath ? "fa-tint" : "fa-draw-polygon";
  var buttonText = showPath ? "Show Cleaned" : "Show Recorded Plan";

  let pathCSS = showPath
    ? `.PlanMap-body .training-path { stroke: black; stroke-width: 0.1; stroke-linejoin: round; stroke-miterlimit:4; stroke-opacity: 0.95; fill: none }`
    : "";

  const pathClassNames = isFillIn
    ? "training-path training-path-filled"
    : "training-path";
  let content;
  content = (
    <svg
      data-testid="panel-plan-image"
      width="100%"
      height="700"
      viewBox={fixedViewBoxValue}
    >
      <image href={maps.map_image_url} height={maps.width} />
      <g transform={matrix}>
        <path d={segmentFill} className={pathClassNames} />
        {kickouts}
      </g>
    </svg>
  );
  return (
    <div className="PlanMap-panel">
      <style>{pathCSS}</style>
      <div className="PlanMap-heading">Map</div>
      <div className="PlanMap-body">
        <div
          data-testid="expand-modal-map"
          className="button is-pulled-left"
          onClick={() => openModal()}
        >
          <i className="fa fa-search-plus" />
        </div>
        <div
          data-testid="panel-toggle-path"
          className="button is-light is-info is-pulled-right map-button"
          onClick={() => togglePath(!showPath)}
        >
          <i className={["fas " + icon]} />
          {buttonText}
        </div>
        <div
          data-testid="panel-toggle-kickout"
          className="button is-light is-danger is-pulled-right kickout-button"
          onClick={toggleKickouts}
        >
          <i className="fas fa-stop-circle" />
          Toggle Kickouts
        </div>
        <div>{content}</div>
      </div>
      {tooltip}
    </div>
  );
};

export default PanelMap;
