import { createContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import qs from "qs";

const AuthClientContext = createContext();

const AuthClientContextProvider = ({ children }) => {
  const { authState } = useOktaAuth();
  if (
    window.Cypress &&
    window.Cypress.spec.name.substring(
      0,
      window.Cypress.spec.name.indexOf(".")
    ) !== "login"
  ) {
    let cypressAuthState = JSON.parse(localStorage.getItem("authState"));
    if (cypressAuthState != null) {
      authState.isAuthenticated = cypressAuthState.isAuthenticated;
      authState.accessToken = JSON.parse(cypressAuthState.accessToken);
      authState.idToken = JSON.parse(cypressAuthState.idToken);
      authState.isPending = cypressAuthState.isPending;
    }
  }
  const token = authState.accessToken;

  const apiEndpoint =
    process.env.REACT_APP_API_URL === undefined
      ? ""
      : process.env.REACT_APP_API_URL;

  const apiClient = axios.create({
    baseURL: apiEndpoint + "/api", // <- ENV variable
  });

  apiClient.interceptors.request.use(
    (config) => {
      return {
        ...config,
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      };
    },
    (error) => Promise.reject(error)
  );
  const { get, post, put, delete: destroy } = apiClient;
  return (
    <AuthClientContext.Provider value={{ get, post, put, destroy }}>
      {children}
    </AuthClientContext.Provider>
  );
};

export { AuthClientContext, AuthClientContextProvider };
