import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { RoleContext } from "../../../../services/Api/Role";
import UserEdit from "../../../../domain/User/UserEdit";
import LoadingIcon from "../../../../components/LoadingIcon";

const CreateUser = ({ match }) => {
  const {
    params: { id },
  } = match;
  const { role } = useContext(RoleContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (role != null) {
      setIsLoaded(true);
    }
  }, [role]);

  let content;
  if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content =
      role === "admin" || role === "superadmin" ? (
        <UserEdit role={role} id={id} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      );
  }

  return <>{content}</>;
};
export default CreateUser;
