import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import PlaybackList from "../../../domain/Device/PlaybackList";
import { useHistory, useLocation } from "react-router-dom";
import ViewHeader from "../../../components/ViewHeader";
import StatsHeader from "../../../domain/Device/StatsHeader";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
import KickoutChart from "../../../domain/Device/KickoutChart";
import RuntimeChart from "../../../domain/Device/RuntimeChart";
import { RoleContext } from "../../../services/Api/Role";
import moment from "moment";
import UpdateDeviceMetadata from "../../../domain/Device/UpdateDeviceMetadata";

const ViewDevice = ({ match }) => {
  const {
    params: { id },
  } = match;

  const query = new URLSearchParams(useLocation().search);
  const sd = query.get("start_date")
    ? moment(query.get("start_date")).startOf("day")
    : null;
  const ed = query.get("end_date")
    ? moment(query.get("end_date")).endOf("day")
    : null;

  const [device, setDevice] = useState(null);
  const [trainings, setTrainings] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTrainingLoaded, setIsTrainingLoaded] = useState(false);

  const [startDate, setStartDate] = useState(sd);
  const [endDate, setEndDate] = useState(ed);

  const history = useHistory();

  const { role } = useContext(RoleContext);

  const { get, destroy } = useContext(AuthClientContext);

  const isAdmin = role === "superadmin" || role === "admin";
  const edit = isAdmin ? () => setModalOpen(true) : null;

  const rowClick = (id) => {
    history.push(`/playbacks/${id}`);
  };

  const kickoutRowClick = (id) => {
    history.push(`/troubleshoot/${id}`);
  };

  const remove = () => {
    destroy(`/devices/${id}`).then(
      () => {
        history.push("/devices");
      },
      (error) => {
        setError(error);
      }
    );
  };

  useEffect(() => {
    get(`/v1/devices/${id}`).then(
      (result) => {
        setDevice(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get]);

  useEffect(() => {
    get(`/v1/devices/${id}/training`).then(
      (result) => {
        setTrainings(result.data);
        setIsTrainingLoaded(true);
      },
      (error) => {
        setError(error);
        setIsTrainingLoaded(true);
      }
    );
  }, [id, get]);

  useEffect(() => {
    let params = {};
    if (startDate) {
      params["start_date"] = startDate.format("YYYY-MM-DD");
    }
    if (endDate) {
      params["end_date"] = endDate.format("YYYY-MM-DD");
    }
    history.replace({
      pathname: `/devices/${id}`,
      search: "?" + new URLSearchParams(params).toString(),
    });
  }, [history, id, startDate, endDate]);

  if (error) {
    return <ErrorIcon text={error.message} />;
  } else if (!isLoaded || !isTrainingLoaded) {
    return <LoadingIcon />;
  } else {
    return (
      <div>
        <ViewHeader
          name="Machine"
          id={device.buddy_name ? device.buddy_name : device.autonomy_kit}
          edit={edit}
        />
        <div className="columns is-multiline">
          <div className="column is-12">
            <StatsHeader
              id={device.autonomy_kit}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
          <div className="column is-12">
            <PlaybackList
              id={device.autonomy_kit}
              type="autonomy_kit"
              rowClick={rowClick}
              fieldTitles={["Plan"]}
              fields={["plan_name"]}
              startDate={startDate}
              endDate={endDate}
            />
          </div>

          <div className="column is-5">
            <RuntimeChart
              id={device.autonomy_kit}
              startDate={startDate}
              endDate={endDate}
            />
            <div data-testid="metadata-box" className="box metadata">
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Machine Name:
                </span>
                <span>{device.buddy_name}</span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Autonomy Kit:
                </span>
                <span>{device.autonomy_kit}</span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Serial Number:
                </span>
                <span>{device.serial_number ? device.serial_number : "-"}</span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Compute Version:
                </span>
                <span>
                  {device.configuration ? device.configuration.version : "-"}
                </span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Training Time:
                </span>
                <span>
                  {trainings.reduce((p, c) => p + c.elapsed_time_seconds, 0) !==
                  0
                    ? trainings.reduce((p, c) => p + c.elapsed_time_seconds, 0)
                    : "-"}
                </span>
              </div>
              <div>
                <span className="has-text-weight-medium is-size-6 mr-4 label">
                  Training Distance (m):
                </span>
                <span>
                  {trainings
                    .reduce((p, c) => p + c.distance_travelled_m, 0)
                    .toFixed(2) + " m"}
                </span>
              </div>
            </div>
          </div>
          <div className="column is-7">
            <KickoutChart
              id={device.autonomy_kit}
              rowClick={kickoutRowClick}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <UpdateDeviceMetadata
            id={id}
            serialNumber={device.serial_number}
            opened={modalOpen}
            close={() => {
              setModalOpen(false);
            }}
          />
        </div>
        {role === "superadmin" && (
          <button className="button is-danger is-outlined" onClick={remove}>
            Remove Machine
          </button>
        )}
      </div>
    );
  }
};

export default ViewDevice;
