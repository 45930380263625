import "./style.css";
import React, { useState, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";

const UpdateDeviceMetadata = ({
  id,
  serialNumber = "",
  opened = false,
  close,
}) => {
  const { post } = useContext(AuthClientContext);

  const [updatedSerialNumber, setUpdatedSerialNumber] = useState(serialNumber);
  const [error, setError] = useState(null);

  const closeModal = () => {
    close();
    setUpdatedSerialNumber(updatedSerialNumber);
    setError(null);
  };

  const submit = () => {
    post(`/v1/devices/${id}/metadata`, {
      serial_number: updatedSerialNumber,
    }).then(
      (_) => {
        close();
      },
      (error) => {
        setError(error);
      }
    );
  };

  return (
    <div className={"modal" + (opened ? " is-active" : "")}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Update Device</p>
          <button
            className="delete"
            aria-label="close"
            onClick={closeModal}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label id="device-serial-number-label" className="label">
              Serial Number
            </label>
            <div className="control">
              <input
                aria-labelledby="device-serial-number-label"
                className="input"
                type="text"
                onChange={(e) => setUpdatedSerialNumber(e.target.value)}
                value={updatedSerialNumber}
              />
            </div>
          </div>
          {error && (
            <div className="notification is-danger">{error.message}</div>
          )}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={submit}>
            Save changes
          </button>
          <button className="button" onClick={closeModal}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default UpdateDeviceMetadata;
