import "./style.css";
import React, { useState, useEffect } from "react";
import moment from "moment";
import DeviceMultiselectDropdown from "../../domain/Device/DeviceMultiselectDropdown";
import DatePicker from "../DatePicker";
import { useHistory, useLocation } from "react-router-dom";
import OrganizationMultiselectDropdown from "../../domain/Organization/OrganizationMultiselectDropdown";

const Filter = ({ handler = () => {} }) => {
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);

  const tl = query.getAll("tenant") ? query.getAll("tenant") : [];
  const [tenantList, setTenantList] = useState(tl);
  const tenantHandleSelect = (id) => {
    let list = [...tenantList];
    if (!list.includes(id)) {
      list.push(id);
    } else {
      list.splice(list.indexOf(id), 1);
    }
    setTenantList(list);
  };

  const dl = query.getAll("device") ? query.getAll("device") : [];
  const [deviceList, setDeviceList] = useState(dl);
  const deviceHandleSelect = (id) => {
    let list = [...deviceList];
    if (!list.includes(id)) {
      list.push(id);
    } else {
      list.splice(list.indexOf(id), 1);
    }
    setDeviceList(list);
  };

  const deviceHandleSelectAll = (ids) => {
    setDeviceList(ids);
  };

  const sd = query.get("start_date")
    ? moment(query.get("start_date")).startOf("day")
    : null;
  const ed = query.get("end_date")
    ? moment(query.get("end_date")).endOf("day")
    : null;

  const [startDate, setStartDate] = useState(sd);
  const [endDate, setEndDate] = useState(ed);

  useEffect(() => {
    let params = {};
    if (startDate) {
      params["start_date"] = startDate.startOf("day").format();
    }
    if (endDate) {
      params["end_date"] = endDate.endOf("day").format();
    }
    if (deviceList.length) {
      params["filterBy[device]"] = deviceList;
    }
    if (tenantList.length) {
      params["filterBy[tenant]"] = tenantList;
    }
    handler(params);
  }, [handler, deviceList, tenantList, endDate, startDate]);

  useEffect(() => {
    let qparams = new URLSearchParams();
    if (startDate) {
      qparams.set("start_date", startDate.startOf("day").format("YYYY-MM-DD"));
    }
    if (endDate) {
      qparams.set("end_date", endDate.endOf("day").format("YYYY-MM-DD"));
    }
    if (tenantList && tenantList.length > 0) {
      tenantList.map((v) => qparams.append("tenant", v));
    }
    if (deviceList && deviceList.length > 0) {
      deviceList.map((v) => qparams.append("device", v));
    }
    history.replace({
      search: "?" + qparams.toString(),
    });
  }, [history, deviceList, tenantList, endDate, startDate]);

  return (
    <div className="level box">
      <div className="level-left">
        <div className="level-item">
          <p className="subtitle is-5">Filters</p>
        </div>
      </div>
      <div className="level-item">
        <OrganizationMultiselectDropdown
          handleSelect={tenantHandleSelect}
          selected={tenantList}
        />
      </div>
      <div className="level-item">
        <DeviceMultiselectDropdown
          handleSelect={deviceHandleSelect}
          handleSelectAll={deviceHandleSelectAll}
          selected={deviceList}
        />
      </div>
      <div className="level-item">
        <DatePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
    </div>
  );
};
export default Filter;
