import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { RoleContext } from "../../../../services/Api/Role";
import UserCreation from "../../../../domain/User/UserCreation";
import LoadingIcon from "../../../../components/LoadingIcon";

const CreateUser = () => {
  const { role, organization } = useContext(RoleContext);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (role && organization) {
      setIsLoaded(true);
    }
  }, [role, organization]);

  let content;
  if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content =
      role === "admin" || role === "superadmin" ? (
        <UserCreation role={role} org={organization} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      );
  }

  return <>{content}</>;
};
export default CreateUser;
