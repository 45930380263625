import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../../services/Api/AuthClient";
import { useHistory } from "react-router-dom";
import LoadingIcon from "../../../../components/LoadingIcon";
import ErrorIcon from "../../../../components/ErrorIcon";

const EditOrganization = ({ match }) => {
  const {
    params: { id },
  } = match;

  const [org, setOrg] = useState({});
  const [tenants, setTenants] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTenantsLoaded, setIsTenantsLoaded] = useState(false);

  const { get, put } = useContext(AuthClientContext);

  const history = useHistory();

  useEffect(() => {
    get(`/admin/v1/organizations/${id}`).then(
      (result) => {
        const data = result.data;
        get(`/admin/v1/organizations/${id}/tenants`).then(
          (result) => {
            setOrg({
              organization_id: id,
              display_name: data.name,
              tenants: result.data,
              add_tenants: result.data.map((a) => a.id),
              alias: data.alias,
            });
            setIsLoaded(true);
          },
          (error) => {
            setError(error);
          }
        );
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get]);

  useEffect(() => {
    get(`/admin/v1/tenants/`).then(
      (result) => {
        setTenants(result.data);
        setIsTenantsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsTenantsLoaded(true);
      }
    );
  }, [id, get]);

  const handleSubmit = (e) => {
    e.preventDefault();
    put(`admin/v1/organizations/${id}/`, org).then(
      () => {
        history.push(`/organizations`);
      },
      (error) => {
        setError(error);
      }
    );
  };

  let content;
  if (error) {
    return <ErrorIcon text={error.message} />;
  } else if (!isLoaded || !isTenantsLoaded) {
    return <LoadingIcon />;
  } else {
    content = (
      <>
        <h1 className="title">Edit Organization</h1>
        <div className="columns">
          <div className="column is-half">
            <form
              className="box"
              onSubmit={(e) => handleSubmit(e)}
              data-testid="user-edit-form"
            >
              <div className="field">
                <label className="label">Company Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    value={org.display_name}
                    onChange={(e) => {
                      let o = { ...org };
                      o.display_name = e.target.value;
                      setOrg(o);
                    }}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Alias</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    value={org.alias}
                    disabled
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-half">
                  <h6 className="title is-6">Current Tenants</h6>
                  <div className="tags">
                    {org.tenants.map((t) => (
                      <span key={t.id} className="tag">
                        {t.name}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="column is-half">
                  <h6 className="title is-6">Add Tenants</h6>
                  <div className="tags">
                    {tenants
                      .filter((i) => !org.add_tenants.includes(i.id))
                      .map((t) => (
                        <span
                          key={t.id}
                          className="tag is-clickable"
                          onClick={(e) => {
                            let o = { ...org };
                            o.tenants.push(t);
                            o.add_tenants.push(t.id);
                            setOrg(o);
                          }}
                        >
                          {t.name}
                        </span>
                      ))}
                  </div>
                </div>
              </div>

              <hr />
              <div className="buttons">
                <button type="submit" className="button is-success">
                  Submit
                </button>
                <button
                  type="submit"
                  className="button"
                  onClick={() => history.push("/organizations")}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  return <div>{content}</div>;
};

export default EditOrganization;
