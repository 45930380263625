import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { RoleContext } from "../../../services/Api/Role";
import LoadingIcon from "../../../components/LoadingIcon";
import LandingLink from "../../../components/Settings/LandingLink";

const Settings = () => {
  const { role } = useContext(RoleContext);
  const [isLoaded, setIsLoaded] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const showWelcome = query.get("onboarding") === "true";

  useEffect(() => {
    if (role != null) {
      setIsLoaded(true);
    }
  }, [role]);

  const isAdmin = role === "superadmin" || role === "admin";
  const isSuperAdmin = role === "superadmin";
  let welcome;
  if (showWelcome) {
    welcome = (
      <div className="notification is-success">
        <button className="delete"></button>
        Welcome! To complete setup please ensure you look at authentication
        options, register a new device, and add any additional users.
      </div>
    );
  }
  let content;
  if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = isAdmin ? (
      <>
        {welcome}
        <div className="columns is-multiline">
          <LandingLink name="Users" link="/users" icon="fa-users" />
          {isSuperAdmin && (
            <LandingLink
              name="Organizations"
              link="/organizations"
              icon="fa-building"
            />
          )}

          <LandingLink
            name="Authentication"
            link={`/`}
            icon="fa-lock"
            disabled={true}
          />
          {isSuperAdmin && (
            <LandingLink
              name="Register Machine"
              link="/devices/register"
              icon="fa-robot"
            />
          )}

          {isSuperAdmin && (
            <LandingLink
              name="Transfer Machine"
              link="/devices/transfer"
              icon="fa-exchange-alt"
            />
          )}

          <LandingLink
            name="Service Accounts"
            link="/"
            icon="fa-cloud"
            disabled={true}
          />
          <LandingLink
            name="Notifications"
            link="/"
            icon="fa-bell"
            disabled={true}
          />
          <LandingLink
            name="Report Bug"
            link="/"
            icon="fa-bug"
            disabled={true}
          />

          {isSuperAdmin && (
            <LandingLink
              name="Logs"
              link="/settings/logs"
              icon="fa-scroll"
              disabled={false}
            />
          )}
        </div>
      </>
    ) : (
      <Redirect to={{ pathname: "/" }} />
    );
  }
  return content;
};
export default Settings;
