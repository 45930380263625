import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import ViewHeader from "../../../components/ViewHeader";
import ErrorIcon from "../../../components/ErrorIcon";
import LoadingIcon from "../../../components/LoadingIcon";

const ViewTroubleshoot = ({ match }) => {
  const {
    params: { id },
  } = match;

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    get(`/v1/troubleshoot_messages/${id}`).then(
      (result) => {
        setData(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [id, get]);
  if (error) {
    return <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    return <LoadingIcon />;
  } else {
    let visualGuide;
    if (data.media && data.media[0].type === "video") {
      const media = data.media[0];
      const code = media.link.substring(
        media.link.lastIndexOf("/") + 1,
        media.length
      );
      visualGuide = (
        <article className="help-message message">
          <div className="message-header">
            <p>Visual Guide</p>
          </div>
          <div className="message-body has-text-centered">
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${code}`}
              title="Troubleshoot Guide"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </article>
      );
    }
    return (
      <div>
        <ViewHeader name="Help" id={id} />

        <div className="columns is-multiline">
          <div className="column is-centered">
            <article className="help-message message">
              <div className="message-header">
                <p>Summary</p>
              </div>
              <div className="message-body">{data.summary}</div>
            </article>
            <article className="help-message message">
              <div className="message-header">
                <p>Display Message</p>
              </div>
              <div className="message-body">{data.display_message}</div>
            </article>
            <article className="help-message message">
              <div className="message-header">
                <p>Operator Help</p>
              </div>
              <div className="message-body">
                <div className="mb-5">
                  <label className="label">Cause</label>
                  <p>{data.operator.cause}</p>
                </div>
                <div>
                  <label className="label">Action</label>
                  <p>{data.operator.action}</p>
                </div>
              </div>
            </article>
            <article className="help-message message">
              <div className="message-header">
                <p>Technician Help</p>
              </div>
              <div className="message-body">
                <div className="mb-5">
                  <label className="label">Cause</label>
                  <p>{data.technician.cause}</p>
                </div>
                <div>
                  <label className="label">Action</label>
                  <p>{data.technician.action}</p>
                </div>
              </div>
            </article>
            {visualGuide}
          </div>
        </div>
      </div>
    );
  }
};

export default ViewTroubleshoot;
