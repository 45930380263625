import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

const DatePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const [focused, setFocused] = useState();
  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="stats-header-start-date"
      endDate={endDate}
      endDateId="stats-header-end-date"
      minimumNights={0}
      isOutsideRange={() => false}
      onDatesChange={({ startDate, endDate }) => {
        if (endDate) {
          endDate = moment(endDate).endOf("day");
        }
        if (startDate) {
          startDate = moment(startDate).startOf("day");
        }
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      focusedInput={focused}
      onFocusChange={(focusedInput) => setFocused(focusedInput)}
    />
  );
};

export default DatePicker;
