import React, { useState, useEffect, createContext } from "react";
import { useOktaAuth } from "@okta/okta-react";

const RoleContext = createContext();

const specialOrgs = [
  "11111111-1111-1111-1111-111111111111", //Thoro
  "6250608e-7a9e-4449-8b17-66e21361aaaf", //Nilfisk
];

const RoleContextProvider = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [role, setRole] = useState(null);
  const [org, setOrg] = useState(null);
  const [special, setSpecial] = useState(false);

  useEffect(() => {
    if (
      window.Cypress &&
      window.Cypress.spec.name.substring(
        0,
        window.Cypress.spec.name.indexOf(".")
      ) !== "login"
    ) {
      if (authState.isAuthenticated) {
        let info = JSON.parse(localStorage.getItem("oktaCypress"));
        setRole(info.user.account_type);
      }
      return;
    }
    if (!authState.isAuthenticated) {
      setRole(null);
    } else {
      oktaAuth.getUser().then((info) => {
        const role =
          info.app_role &&
          info.app_role.length &&
          info.app_role[0].split(":").length > 2
            ? info.app_role[0].split(":")[2]
            : "user";
        setRole(role);
        const org =
          info.org && info.org.length && info.org[0].split(":").length > 2
            ? info.org[0].split(":")[2]
            : "";
        setOrg(org);
        setSpecial(specialOrgs.includes(org));
      });
    }
  }, [authState, oktaAuth]);

  return (
    <RoleContext.Provider
      value={{ role: role, organization: org, special: special }}
    >
      {children}
    </RoleContext.Provider>
  );
};

export { RoleContext, RoleContextProvider };
