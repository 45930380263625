import "./style.css";
import { React, useState } from "react";
import { Link } from "react-router-dom";

const limitedViewOrgs = [
  "bb75cdf9-021b-4721-ae04-f6c5059d3404", // derichebourg
  "2ccd31ac-e272-4586-8bf4-b10ef62ef95d", // nicollin
  "d2c46ab1-a19a-429c-935f-a6b3f4568ceb", // atalian
  "601d62cd-d271-4eca-877d-9f0660a1d286", // m2h
];

const Navbar = ({
  isPending,
  isAuthenticated,
  loginClick,
  logoutClick,
  homeClick,
  userInfo,
}) => {
  const userName =
    !isPending && isAuthenticated && userInfo ? userInfo.name : "";
  const role =
    !isPending &&
    isAuthenticated &&
    userInfo &&
    userInfo.app_role &&
    userInfo.app_role.length &&
    userInfo.app_role[0].split(":").length > 2
      ? userInfo.app_role[0].split(":")[2]
      : "user";
  const isAdmin = role === "superadmin" || role === "admin";
  const isSuperAdmin = role === "superadmin";
  const userorgs = userInfo
    ? userInfo.org.map((item) => item.split(":")[2])
    : [];
  const limitedView =
    userorgs.length > 0
      ? limitedViewOrgs.some((r) => userorgs.includes(r))
      : true;
  const [isActive, setIsActive] = useState(false);
  return (
    <nav
      className="navbar is-white"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a
          href="/"
          className="navbar-item"
          onClick={homeClick}
          data-testid="thoro-logo"
        >
          <img alt="" src="/images/thoro-logo.png" width="150" height="50" />
        </a>

        <span
          onClick={() => {
            setIsActive(!isActive);
          }}
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </span>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
      >
        {!isPending && isAuthenticated && (
          <div className="navbar-start">
            {!limitedView && (
              <Link to="/devices" className="navbar-item">
                Machines
              </Link>
            )}
            {isSuperAdmin && (
              <Link to="/plans" className="navbar-item">
                Plans
              </Link>
            )}
            <Link to="/reports" className="navbar-item">
              Reports
            </Link>
          </div>
        )}

        {!isPending && isAuthenticated && (
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">{userName}</div>
              <div className="navbar-dropdown is-right">
                <a className="navbar-item NavbarItemLink" href="/troubleshoot">
                  Help
                </a>
                <a className="navbar-item NavbarItemLink" href="/feedback">
                  Feedback
                </a>
                {isAdmin && (
                  <a className="navbar-item NavbarItemLink" href="/settings">
                    Settings
                  </a>
                )}
                <hr className="navbar-divider" />
                <div
                  className="navbar-item NavbarItemLink"
                  onClick={logoutClick}
                >
                  Logout<span>&nbsp;</span>
                  <i className="fas fa-sign-out-alt"></i>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
