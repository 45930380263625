import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { AuthClientContext } from "../../../services/Api/AuthClient";
import LoadingIcon from "../../../components/LoadingIcon";
import ErrorIcon from "../../../components/ErrorIcon";
const UserList = ({ rowClick }) => {
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { get } = useContext(AuthClientContext);

  useEffect(() => {
    get("/admin/v1/users/").then(
      (result) => {
        setList(result.data);
        setIsLoaded(true);
      },
      (error) => {
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [get]);

  const colors = { superadmin: "success", admin: "link", user: "warning" };
  const parseTags = (tags) => {
    return tags.reduce((acc, t) => {
      acc.push(<span className={"tag is-light is-" + colors[t]}>{t}</span>);
      return acc;
    }, []);
  };
  let content;
  if (error) {
    content = <ErrorIcon text={error.message} />;
  } else if (!isLoaded) {
    content = <LoadingIcon />;
  } else {
    content = (
      <table data-testid="user-table" className="table UserList is-hoverable">
        <thead>
          <tr>
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => (
            <tr key={item.id} onClick={() => rowClick(item.id)}>
              <td className="link-text">{item.username}</td>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>{parseTags(item.roles)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return <div className="column is-12">{content}</div>;
};

export default UserList;
