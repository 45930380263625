const ErrorIcon = ({ text }) => {
  return (
    <div
      data-testid="error-icon"
      className="has-text-centered pt-4 pb-4"
      style={{ width: "100%" }}
    >
      <i className="fa-3x fas fa-exclamation-circle"></i>
      <div>{text}</div>
    </div>
  );
};

export default ErrorIcon;
